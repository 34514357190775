var therapyStatusService = {

    createStatusList ( therapyStatusId, internal ) {

        let list = [];

        console.log( 'Start therapyStatusService::createStatusList ( filterStatusId: \'' + therapyStatusId +  '\', internal: \'' + internal + '\')' );

        list.push({
            id: '1',
            title: 'New'
        });
        list.push({
            id: '7',
            title: 'Verified'
        });
        list.push({
            id: '2',
            title: 'Contacted'
        });
        list.push({
            id: '3',
            title: 'Currently Trialling'
        });
        list.push({
            id: '4',
            title: 'Completed & on-going'
        });
        list.push({
            id: '5',
            title: 'Declined'
        });
        list.push({
            id: '6',
            title: 'Terminated'
        });

        console.log( 'Complete therapyStatusService::createStatusList ( filterStatusId: \'' + therapyStatusId +  '\', internal: \'' + internal + '\')' );

        return list;
    },

    GetStatusDescription( therapyStatusId ) {

        console.log( 'Start therapyStatusService::GetStatusDescription ( filterStatusId: \'' + therapyStatusId +  '\')' );

        switch (therapyStatusId) {

            case '1':
                return 'New';

            case '2':
                return 'Contacted';

            case '3':
                return 'Currently Trialling';

            case '4':
                return 'Completed & on-going';

            case '5':
                return 'Declined';

            case '6':
                return 'Terminated';

            case '7':
                return 'Verified';

            default:
                return '';
        }
    }
}

export default therapyStatusService;
