import AuthService from "./auth-service";
import WebService from "./web-service";

class reports extends WebService {
  static getHeaderConfig() {
    return {
      baseURL: this.getBaseUrl(),
      headers: {
        authtoken: AuthService.getToken(),
        post: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    };
  }

  static getTherapyAttachment(therapyAttachemntId) {
    const serverUrl = this.checkUrlFormat(this.getBaseUrl());

    const url =
      serverUrl +
      "/attachment/GetTherapyAttachment" +
      "?TherapyAttachmentId=" +
      therapyAttachemntId +
      "&authToken=" +
      AuthService.getToken();

    console.log(url);

    return url;
  }

  static makeTherapyByLocationAllUrl(startDate, endDate, reportFormat) {
    const serverUrl = this.checkUrlFormat(this.getBaseUrl());

    const url =
      serverUrl +
      "report/TherapyByLocationAll" +
      "?startDate=" +
      startDate.format("DD-MMM-YYYY") +
      "&endDate=" +
      endDate.format("DD-MMM-YYYY") +
      "&reportFormat=" +
      reportFormat +
      "&authToken=" +
      AuthService.getToken();

    console.log(url);

    return url;
  }

  static makeTherapyByLocationOneUrl(
    startDate,
    reportingPeriod,
    reportingLocation,
    reportFormat
  ) {
    const serverUrl = this.checkUrlFormat(this.getBaseUrl());

    const url =
      serverUrl +
      "report/TherapyByLocationOne?" +
      "startDate=" +
      startDate.format("DD-MMM-YYYY") +
      "&reportingPeriod=" +
      reportingPeriod +
      "&reportingLocation=" +
      reportingLocation +
      "&ReportFormat=" +
      reportFormat +
      "&authToken=" +
      AuthService.getToken();

    console.log(url);

    return url;
  }

  static makeTherapyHistoryUrl(therapyId, reportFormat) {
    const serverUrl = this.checkUrlFormat(this.getBaseUrl());

    const url =
      serverUrl +
      "report/TherapyNoteHistory?" +
      "TherapyId=" +
      therapyId +
      "&ReportFormat=" +
      reportFormat +
      "&authToken=" +
      AuthService.getToken();

    console.log(url);

    return url;
  }

  static makeTherapyScriptUrl(therapyScriptId, reportFormat) {
    const serverUrl = this.checkUrlFormat(this.getBaseUrl());

    const url =
      serverUrl +
      "report/TherapyScript?" +
      "TherapyScriptId=" +
      therapyScriptId +
      "&ReportFormat=" +
      reportFormat +
      "&authToken=" +
      AuthService.getToken();

    console.log(url);

    return url;
  }

  static makeTherapyScriptWithParamsUrl(
    firstName,
    surname,
    dateOfBirth,
    diagnosticAHI,
    postTherapyAHI,
    preTherapyESS,
    postTherapyESS,
    symptomaticImprovementScore,
    averageDailyUsageHours,
    currentMask,
    currentMachinePreasureSettings,
    reportFormat
  ) {
    const serverUrl = this.checkUrlFormat(this.getBaseUrl());

    const url =
      serverUrl +
      "report/TherapyScriptWithParams?" +
      "FirstName=" +
      firstName +
      "&Surname=" +
      surname +
      "&DateOfBirth=" +
      dateOfBirth +
      "&DiagnosticAHI=" +
      diagnosticAHI +
      "&PostTherapyAHI=" +
      postTherapyAHI +
      "&PreTherapyESS=" +
      preTherapyESS +
      "&PostTherapyESS=" +
      postTherapyESS +
      "&SymptomaticImprovementScore=" +
      symptomaticImprovementScore +
      "&AverageDailyUsage=" +
      averageDailyUsageHours +
      "&CurrentMask=" +
      currentMask +
      "&CurrentMachinePreasureSettings=" +
      currentMachinePreasureSettings +
      "&ReportFormat=" +
      reportFormat +
      "&authToken=" +
      AuthService.getToken();

    console.log(url);

    return url;
  }
}

export default reports;
