import React from "react";
import Modal from "react-modal";
import CreateReactClass from "create-react-class";
import SAlert from "sweetalert2";

import Reports from "../../services/report-service";
import Therapy from "../../services/therapy-service";
import ScriptValidationService from "../../services/script-validation-service";

import logo from "./images/script.png";

import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../css/login.css";

/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "740px",
    height: "690px",
    marginleft: "auto",
    marginRight: "auto",
    transform: "translate(-50%, -50%)",
    background: "white",
    borderColor: "black"
  }
};

export default CreateReactClass({
  getInitialState: function() {
    return {
      modalIsOpen: false,
      info: {}
    };
  },

  componentDidMount() {
    Therapy.getTherapyInfo(this.props.id).then(
      function(info) {
        this.setState({
          info: info.data[0]
        });
      }.bind(this)
    );
  },

  onOpenModal() {
    this.setState({
      modalIsOpen: true
    });
  },

  afterOpenModal: function() {
    return true;
  },

  onCloseModal: function() {
    this.setState({
      modalIsOpen: false
    });
  },

  validateScript(isPrint) {
    return ScriptValidationService.validateScript({
      diagnosticAHI: Number(this.DiagnosticAhiControl.value),
      postTherapyAHI: Number(this.PostTherapyAhiControl.value),
      preTherapyESS: Number(this.PreTherapyEssControl.value),
      postTherapyESS: Number(this.PostTherapyEssControl.value),
      symptomaticImprovementScore: Number(
        this.SymptomaticImprovementScoreControl.value
      ),
      averageDailyUsageHours: Number(this.AverageDailyUsageControl.value),
      currentMask: this.CurrentMaskControl.value,
      currentMachinePressureSettings: this.CurrentMachinePressureSettingsControl
        .value,
      physicianOverride: this.PhysicianOverrideControl.checked,
      note: this.NoteControl.value,
      isPrint: isPrint
    });
  },

  onPrintAllClick: function() {
    var ele = document.getElementById("downloadFrame");

    if (ele) {
      document.body.removeChild(ele);
    }

    var downloadFrame = document.createElement("iframe");
    downloadFrame.id = "downloadFrame";
    downloadFrame.src = Reports.makeTherapyScriptUrl(this.props.id, "PDF");
    //downloadFrame.style = 'display:none';
    downloadFrame.className = "hidden";
    document.body.appendChild(downloadFrame);
  },

  onPrintClick: function() {
    var resp = this.validateScript(true);
    var self = this;

    if (resp.valid === false) {
      SAlert({
        title: "Validation",
        html: resp.message,
        type: "error",
        showCloseButton: true,
        width: 900
      });
      return;
    }

    SAlert({
      title: "<b>Current Changes</b>",
      html:
        "You need to save the script before you print. Do you wish to continue?",
      type: "error",
      showCloseButton: true,
      confirmButtonText: "Continue",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      width: 900
    }).then(function(result) {
      if (result) {
        self.addScript(self);

        var ele = document.getElementById("downloadFrame");

        if (ele) {
          document.body.removeChild(ele);
        }

        var downloadFrame = document.createElement("iframe");
        downloadFrame.id = "downloadFrame";
        downloadFrame.src = Reports.makeTherapyScriptUrl(
          self.state.therapyScriptId,
          "PDF"
        );
        downloadFrame.className = "hidden";
        document.body.appendChild(downloadFrame);
      }
    });
  },

  onSaveClick: function(e) {
    var resp = this.validateScript(false);

    if (resp.valid === false) {
      SAlert({
        title: "Validation",
        html: resp.message,
        type: "error",
        showCloseButton: true,
        width: 900
      });
      return;
    }

    this.addScript(this);
  },

  addScript(self) {
    var info = self.state.info;

    info = {
      diagnosticAhi: self.DiagnosticAhiControl.value,
      postTherapyAhi: self.PostTherapyAhiControl.value,
      preTherapyEss: self.PreTherapyEssControl.value,
      postTherapyEss: self.PostTherapyEssControl.value,
      symptomaticImprovementScore:
        self.SymptomaticImprovementScoreControl.value,
      averageDailyUsage: self.AverageDailyUsageControl.value,
      currentMask: self.CurrentMaskControl.value,
      currentMachinePressureSettings:
        self.CurrentMachinePressureSettingsControl.value,
      physicianOverride: self.PhysicianOverrideControl.checked,
      note: self.NoteControl.value
    };

    Therapy.addTherapyScript(
      self.props.id,
      info.diagnosticAhi,
      info.postTherapyAhi,
      info.preTherapyEss,
      info.postTherapyEss,
      info.symptomaticImprovementScore,
      info.averageDailyUsage,
      info.currentMask,
      info.currentMachinePressureSettings,
      info.physicianOverride,
      info.note
    ).then(function(resp) {
      self.setState({
        modalIsOpen: false
      });

      self.props.addClick();
    });
  },

  render: function() {
    var title;

    if (this.state.info !== undefined)
      title = (
        <h3>
          New Therapy Script:{" "}
          {this.state.info.surname + ", " + this.state.info.firstName}
        </h3>
      );
    else title = <h3>New Therapy Script:</h3>;

    return (
      <div className="form-group btn-toolbar">
        <button
          className="btn btn-primary btn-sm ss-button"
          onClick={this.onOpenModal}
          tabIndex="55"
          autoFocus
        >
          Add New
        </button>
        <button
          className="btn btn-secondry btn-sm ss-button"
          onClick={this.props.closeClick}
          tabIndex="56"
        >
          Close
        </button>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          style={customStyles}
        >
          <div>
            <div className="panel panel-default">
              <div className="panel-body title-panel">
                <div className="header">
                  <img
                    src={logo}
                    title="Add Therapy Patient"
                    alt="Add Therapy Patient"
                    onClick={this.openModal}
                  />

                  {title}
                </div>
              </div>
            </div>

            <div>
              <br />
              <form className="form-horizontal">
                <div className="form-group row">
                  <label
                    for="DiagnosticAhiControl"
                    className="col-sm-6 control-label"
                  >
                    Diagnostic AHI :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="DiagnosticAhiControl"
                      min="0"
                      max="100"
                      step="1"
                      onChange={this.onDiagnosticAhiChanged}
                      ref={c => {
                        this.DiagnosticAhiControl = c;
                      }}
                      placeholder="Number"
                      autoFocus
                      tab="1"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="PostTherapyAhiControl"
                    className="col-sm-6 control-label"
                  >
                    Post Therapy AHI :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="PostTherapyAhiControl"
                      onChange={this.onPostTherapyAhiChanged}
                      ref={c => {
                        this.PostTherapyAhiControl = c;
                      }}
                      placeholder="Number"
                      tab="2"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="PreTherapyEssControl"
                    className="col-sm-6 control-label"
                  >
                    Pre Therapy ESS :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="PreTherapyEssControl"
                      onChange={this.onPreTherapyEssChanged}
                      ref={c => {
                        this.PreTherapyEssControl = c;
                      }}
                      placeholder="Number"
                      tab="3"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="PostTherapyEssControl"
                    className="col-sm-6 control-label"
                  >
                    Post Therapy ESS :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="PostTherapyEssControl"
                      onChange={this.onPostTherapyEssChanged}
                      ref={c => {
                        this.PostTherapyEssControl = c;
                      }}
                      placeholder="Number"
                      tab="4"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="SymptomaticImprovementScoreControl"
                    className="col-sm-6 control-label"
                  >
                    Symptomatic Improvement Score :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="SymptomaticImprovementScoreControl"
                      onChange={this.onSymptomaticImprovementScoreChanged}
                      ref={c => {
                        this.SymptomaticImprovementScoreControl = c;
                      }}
                      placeholder="Number"
                      tab="5"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="AverageDailyUsageControl"
                    className="col-sm-6 control-label"
                  >
                    Average Daily Usage (Hours) :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="AverageDailyUsageControl"
                      onChange={this.onAverageDailyUsageChanged}
                      ref={c => {
                        this.AverageDailyUsageControl = c;
                      }}
                      placeholder="Number"
                      tab="6"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="CurrentMaskControl"
                    className="col-sm-6 control-label"
                  >
                    Current Mask :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="CurrentMaskControl"
                      onChange={this.onCurrentMaskChanged}
                      ref={c => {
                        this.CurrentMaskControl = c;
                      }}
                      placeholder="Current Mask"
                      tab="7"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="CurrentMachinePressureSettingsControl"
                    className="col-sm-6 control-label"
                  >
                    Current Machine Pressure Settings :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="CurrentMachinePressureSettingsControl"
                      onChange={this.oncurrentMachinePressureSettingsChanged}
                      ref={c => {
                        this.CurrentMachinePressureSettingsControl = c;
                      }}
                      placeholder="Current Machine Pressure Settings"
                      tab="8"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="PhysicianOverrideControl"
                    className="col-sm-6 control-label"
                  >
                    Physician Override :
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="checkbox"
                      className="form-control form-control-sm"
                      id="PhysicianOverrideControl"
                      onChange={this.onPhysicianOverrideChanged}
                      ref={c => {
                        this.PhysicianOverrideControl = c;
                      }}
                      placeholder="Physician Override"
                      tab="9"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label for="NoteControl" className="col-sm-2 control-label">
                    Notes :
                  </label>
                  <div className="col-sm-10">
                    <textarea
                      className="form-control form-control-sm"
                      id="NoteControl"
                      onChange={this.onNoteChanged}
                      ref={c => {
                        this.NoteControl = c;
                      }}
                      placeholder="Notes"
                      tab="10"
                    />
                  </div>
                </div>

                <br />

                <div className="btn-toolbar pull-right" data-toggle="buttons">
                  <button
                    type="button"
                    className="btn btn-primary ss-button"
                    onClick={this.onPrintClick}
                    tab="11"
                  >
                    Print
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondry ss-button"
                    onClick={this.onSaveClick}
                    tab="11"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondry ss-button"
                    onClick={this.onCloseModal}
                    tab="12"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
});
