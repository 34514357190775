import  React                           from 'react';
import CreateReactClass                 from 'create-react-class';
import  PropTypes                       from 'prop-types';

import  'react-dropdown/style.css';

//export default React.createClass({
const CheckboxReadOnly = CreateReactClass({

    propTypes: {
        value: PropTypes.bool
    },

    getInitialState: function () {

        const v = this.props.value;

        return {
            value: v
        };
    },

    render: function () {

        return (
            <input type="checkbox" checked={this.state.value} disabled="disabled"/>
        )
    }
});

export default CheckboxReadOnly;