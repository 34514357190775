import React                            from 'react';
import CreateReactClass                 from 'create-react-class';

export default CreateReactClass({

    render: function () {
        return (
            <div className="grid-header-background">
                <div className="horizontal-tall-header-text">
                    {this.props.text}
                </div>
            </div>
        )
    }
});
