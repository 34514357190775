import React                            from 'react';
import CreateReactClass                 from 'create-react-class';
import DropZone                         from 'react-dropzone';

// const styles = {
//     dropZone: {
//         width: 500,
//         height: 65
//     }
// };

//export default React.createClass({
var FileUpload = CreateReactClass({
    
    getInitialState() {

        return {
            attachments: this.props.attachments,
            therapyId: this.props.therapyId,
            files: []
        };
    },

    render() {

        var controlText;

        if (this.props.fullText) {
            controlText = <p>{this.props.fullText}</p>;
        }
        else {
            controlText = <p><br />Drag and drop some files here, or click to select files to upload.<br /><br /><note>{this.props.text}</note></p>;
        }

        return (

            <div style={{ padding: '5px', border: '1px solid black' }} ref="parent1"> 
                <DropZone id="DropZoneControl" onDrop={this.props.onDrop} style={{ width: '1045px', height: '65px' }} ref={(c) => {this.DropZoneControl = c;}} tabIndex="61" >
                {/* <DropZone onDrop={this.props.onDrop}  tabIndex="61" > */}
                    <div >
                        { controlText }
                    </div>
                </DropZone>
            </div>
        );
    }
});

export default FileUpload;