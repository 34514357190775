import React from "react";
import CreateReactClass from "create-react-class";
import ReactDataGrid from "react-data-grid";

import RowRenderer from "./therapy-attachment-row-renderer";
import TherapyAttachmentDelete from "./therapy-attachment-delete";
import TherapyAttachmentViewer from "./therapy-attachment-viewer";

//export default React.createClass({
const TherapyAttachmentTable = CreateReactClass({
  getInitialState() {
    return {
      rows: []
    };
  },

  componentDidMount() {
    // console.log('AttachmentTable::componentDidMount');

    this.setState({
      rows: this.props.attachments
    });
  },

  refreshTable(newRows) {
    // console.log('AttachmentTable::refreshTable');
    //this.props.refreshTable();

    this.setState({
      rows: newRows
    });
  },

  onDeleteRow(therapyAttachmentId) {
    const data = this.state.rows;
    const newData = [];
    let i = -1;
    let j = -1;

    newData.length = data.length;

    if (newData.length === 0) {
      return;
    }

    while (i < newData.length - 1) {
      i++;

      console.log("Loop i=" + i + " j=" + j);
      console.log(
        "Loop id=" + data[i].attachmentID + " therapyId=" + therapyAttachmentId
      );

      if (data[i].attachmentID === therapyAttachmentId) {
        i++;
      }

      if (i < data.length) {
        j++;

        console.log("Loop i=" + i + " j=" + j);
        console.log("data[" + i + "]=" + data[i]);
        console.log("newData[" + j + "]=" + newData[j]);

        newData[j] = data[i];
      }
    }

    newData.length = j + 1;

    this.setState({
      rows: newData
    });
  },

  onTherapyAttachmentClick() {},

  buildColumns() {
    return [
      {
        key: "attachmentID",
        name: "",
        editable: false,
        width: 48,
        editor: <TherapyAttachmentDelete onDeleteRow={this.onDeleteRow} />,
        formatter: <TherapyAttachmentDelete onDeleteRow={this.onDeleteRow} />
      },
      {
        key: "attachmentName",
        name: "Name",
        resizable: true,
        getPdfUrl: this.props.getPdfUrl
      },
      {
        key: "viewID",
        name: "View",
        width: 75,
        formatter: (
          <TherapyAttachmentViewer
            onImageClick={this.onTherapyAttachmentClick}
          />
        ),
        editable: false
      }
    ];
  },

  handleCellSelected(cell) {
    if (cell.idx === 1) {
      const rowData = this.state.rows[cell.rowIdx];

      this.props.setPdfUrl(rowData.attachmentID);
    }
  },

  rowGetter(rowIdx) {
    return this.state.rows[rowIdx];
  },

  render() {
    if (this.state.rows === undefined) return <h5>Loading...</h5>;

    let columns = this.buildColumns();

    let rowRenderer = <RowRenderer />;

    return (
      <div>
        <ReactDataGrid
          ref="grid"
          columns={columns}
          rowGetter={this.rowGetter}
          rowRenderer={rowRenderer}
          onCellSelected={this.handleCellSelected}
          rowsCount={this.state.rows.length}
          rowHeight={50}
          minHeight={550}
          width={250}
          tabIndex="51"
        />
      </div>
    );
  }
});

export default TherapyAttachmentTable;
