import React from "react";
import Modal from "react-modal";
import PDF from "react-pdf-js";
import SAlert from "sweetalert2";

import TherapyAttachmentTable from "./therapy-attachment-table";

import FileUpload from "../../controls/file-upload";

import Reports from "../../services/report-service";
import authService from "../../services/auth-service";
import Therapy from "../../services/therapy-service";

import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "./css/pdf-viewer.css";
/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "1100px",
    height: "800px",
    marginLeft: "auto",
    marginRight: "auto",
    transform: "translate(-50%, -50%)",
    background: "white",
    borderColor: "black"
  }
};

//export default React.createClass({
//const TherapyAttachmentDisplay = CreateReactClass({
class TherapyAttachmentDisplay extends React.Component {
  state = {
    attachments: [],
    modalIsOpen: false,
    pdfUrl: null,
    info: {}
  };

  getInitialState = () => {
    return {
      attachments: [],
      modalIsOpen: false,
      pdfUrl: null,
      info: {}
    };
  };

  componentDidMount() {
    Modal.setAppElement("body");
  }

  onDocumentComplete = pages => {
    this.setState({
      page: 1,
      pages: pages
    });
  };

  onPageComplete = page => {
    this.setState({
      page
    });
  };

  handlePrevious = () => {
    this.setState({
      page: this.state.page - 1
    });
  };

  handleNext = () => {
    this.setState({
      page: this.state.page + 1
    });
  };

  renderPagination = (page, pages) => {
    let previousButton = (
      <li className="previous" onClick={this.handlePrevious}>
        <a href="#top">
          <i className="fa fa-arrow-left" /> Previous
        </a>
      </li>
    );
    if (page === 1) {
      previousButton = (
        <li className="previous diSAlertbled">
          <a href="#top">
            <i className="fa fa-arrow-left" /> Previous
          </a>
        </li>
      );
    }

    let nextButton = (
      <li className="next" onClick={this.handleNext}>
        <a href="#top">
          Next <i className="fa fa-arrow-right" />
        </a>
      </li>
    );
    if (page === pages) {
      nextButton = (
        <li className="next disabled">
          <a href="#top">
            Next <i className="fa fa-arrow-right" />
          </a>
        </li>
      );
    }

    return (
      <nav className="pagerNav">
        <ul>
          {previousButton} {nextButton}
        </ul>
      </nav>
    );
  };

  loadData = () => {
    Therapy.getTherapyAttachments(this.props.id).then(response => {
      if (this._dataTable) {
        this._dataTable.refreshTable(response.data.value);
      }
    });
  };

  openModal = () => {
    Therapy.getTherapyInfo(this.props.id).then(
      function(info) {
        this.setState({
          info: info.data[0],
          modalIsOpen: true
        });
      }.bind(this)
    );

    this.loadData();
  };

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  onAddNew = () => {};

  onDrop = files => {
    if (files.length > 1) {
      SAlert({
        title: "Attachment Upload",
        html: "Please only drop one file at a time.",
        type: "warning",
        width: 700
      });
      return;
    }

    const file = files[0];

    if (file.name.toLowerCase().endsWith(".pdf") === false) {
      SAlert({
        title: "Attachment Upload",
        html: "This is not a PDF file and <b>can not<b/> be attached.",
        type: "warning",
        width: 700
      });
      return;
    }

    let user = authService.getUser();

    if (file.size > user.fileDownloadMaxSize * 1024 * 1000) {
      SAlert({
        title: "Attachment Upload",
        html:
          "This file is larger than you are allowed to attach. Max Size: " +
          user.fileDownloadMaxSize +
          " MB",
        type: "warning",
        width: 700
      });
      return;
    }

    Therapy.uploadTherapyAttachment(this.props.id, file.name, "file desc", file)
      .then(resp => {
        if (resp.status !== 200) {
          SAlert({
            title: "Attachment upload",
            html: resp.data,
            type: "warning"
          });
          return;
        }

        this.loadData();

        SAlert({
          title: "Upload Attachment",
          html: "<b>Attachment uploaded:</b><br/> " + file.name,
          type: "info"
        });
      })
      .catch(() => {
        SAlert({
          title: "Upload Attachment",
          html: "<b>Failed to upload attachment:</b><br/>" + file.name,
          type: "warning"
        });
      });
  };

  onCloseModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  refreshTable = () => {
    this.loadData();
  };

  getPdfUrl = attachmentId => {
    console.log(
      "therapy-attachment-display::getPdfUrl(" + attachmentId.toString() + ")"
    );
    const url = Reports.getTherapyAttachment(attachmentId);

    console.log(url);
    this.setState({
      pdfUrl: url
    });
  };

  setPdfUrl = attachmentId => {
    console.log(
      "therapy-attachment-display::getPdfUrl(" + attachmentId.toString() + ")"
    );
    const url = Reports.getTherapyAttachment(attachmentId);

    console.log(url);
    this.setState(
      {
        pdfUrl: null
      },
      () => {
        this.setState({ pdfUrl: url });
      }
    );
  };

  render = () => {
    let pagination;

    if (this.state.pages) {
      pagination = this.renderPagination(this.state.page, this.state.pages);
    }

    let pdfViewer = null;

    if (this.state.pdfUrl !== null) {
      console.log("Attachment Url: " + this.state.pdfUrl);
      pdfViewer = (
        <div>
          <PDF
            file={this.state.pdfUrl}
            onDocumentComplete={this.onDocumentComplete}
            onPageComplete={this.onPageComplete}
            page={this.state.page}
            scale={0.7}
          />

          {pagination}
        </div>
      );
    } else {
      pdfViewer = <div />;
    }

    const message = (
      <span>
        Drag and drop file, or click to select file to upload
        <br />
        <br />
        Must be a <b>PDF</b> Document.
      </span>
    );

    let title;

    if (this.state.info !== undefined) {
      title = (
        <h3>
          Therapy Attachments:{" "}
          {this.state.info.surname + ", " + this.state.info.firstName}
        </h3>
      );
    } else {
      title = <h3>Therapy Attachments</h3>;
    }

    return (
      <div>
        <img
          src={this.props.theImage}
          title="Display attachments"
          alt="Display attachments"
          onClick={this.openModal}
          height="36"
          width="36"
        />

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          style={customStyles}
        >
          <div>
            <div className="panel panel-default">
              <div className="panel-body title-panel">
                <div className="header">
                  <img
                    src={this.props.theImage}
                    title="Display attachments"
                    alt="Display attachments"
                    onClick={this.openModal}
                    height="24"
                    width="24"
                  />
                  {title}
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-6 col-xs-6">
                  <div>
                    <TherapyAttachmentTable
                      ref={dt => {
                        this._dataTable = dt;
                      }}
                      refreshTable={this.refreshTable}
                      getPdfUrl={this.setPdfUrl}
                      setPdfUrl={this.setPdfUrl}
                      tabIndex="50"
                    />
                  </div>
                  <br />
                  <div>
                    <FileUpload
                      Id={this.props.id}
                      ref={c => {
                        this.fileUpload = c;
                      }}
                      onDrop={this.onDrop}
                      fullText={message}
                      tabIndex="60"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xs-6">{pdfViewer}</div>
              </div>
              <br />
              <div className="btn-toolbar pull-right" data-toggle="buttons">
                <button
                  className="btn btn-primary btn-sm ss-button"
                  onClick={this.onCloseModal}
                  tabIndex="70"
                  autoFocus
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };
}

export default TherapyAttachmentDisplay;
