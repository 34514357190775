import Auth                             from './auth-service';

var _list = [];

var studies = {

    getNextId: function () {

        var id = 0;

        for (var i = 0; i < _list.length; i++) {
            if (_list[i].id > id)
                id = _list[i].id;
        }

        return id + 1;
    },

    addFile: function (file) {

        return new Promise((resolve, reject) => {

            try {

                let isValid = true;
                let message = '';
                let user = Auth.getUser();
                
                if (file.size > user.fileDownloadMaxSize * 1024 * 1000) {
                    isValid = false;
                    message = 'File is larger than allowed. Max Size: ' + user.fileDownloadMaxSize + ' MB';
                }
        
                var nextId = this.getNextId();
        
                _list.push({ 
                    id: nextId, 
                    name: file.name, 
                    size: file.size, 
                    message: message, 
                    image: file, 
                    isValid: isValid, 
                    sent: false 
                });

                resolve( true );
            }
            catch(ex) {

                reject( ex.message );
            }
        })
    },

    getValid: function () {

        var valid = [];

        for (var i = 0; i < _list.length; i++) {
            if (_list[i].isValid)
                valid.push(_list[i]);
        }

        return valid;
    },

    hasValid: function () {

        for (var i = 0; i < _list.length; i++) {
            if (_list[i].isValid) {
                return true;
            }
        }

        return false;
    },

    list: function () {

        return _list;
    },

    removeId ( id ) {

        for (var i = _list.length - 1; i >= 0; i--) {
            
            if (_list[i].id === id) {

                _list.splice(i, 1);
            }
        }
    },

    remove: function () {

        for (var i = _list.length - 1; i >= 0; i--) {

            if (_list[i].isValid && _list[i].sent) {

                _list.splice(i, 1);
            }
        }
    },

    reset: function () {

        _list = [];
    }
};

export default studies;
