import React from "react";

import Logout from "../logout/logout";

import SleepContact from "../contacts/sleep-contact";
import TherapyContact from "../contacts/therapy-contact";

import AuthService from "../../services/auth-service";

import logo1 from "./images/sleep-wa-logo.png";
import logo2 from "./images/sleep-therapy-wa-logo.png";
import config from "../../modules/config";
//import WebService from "../../services/web-service";

class PageHeader extends React.Component {
  constructor(props) {
    super(props);

    this.user = AuthService.getUser();
  }

  render() {
    let logoutButton;
    let sleepContact;
    let therapyContact;

    sleepContact = <SleepContact />;
    therapyContact = <TherapyContact />;

    if (AuthService.loggedIn() === true) {
      logoutButton = <Logout />;
    }

    return (
      <div>
        <div className="headerContent">
          <table style={{ width: "1260px" }}>
            <tbody>
              <tr>
                <td style={{ width: "210px" }}>
                  <img src={logo1} alt="logo 1" height="50px" />
                </td>
                <td style={{ width: "220px" }}>
                  <img src={logo2} alt="logo 2" height="50px" />
                </td>
                <td style={{ width: "220px" }}>
                  <div
                    className="headerCell"
                    style={{ textAlign: "center", margin: "9px" }}
                  >
                    <p
                      style={{
                        fontSize: "35px",
                        fontWeight: "bold",
                        color: "#54007F"
                      }}
                    >
                      Therapy
                    </p>
                  </div>
                </td>
                <td>
                  <div
                    className="headerCell"
                    style={{
                      textAlign: "left",
                      color: "white",
                      background: "#54007F"
                    }}
                  >
                    <h4>
                      {/* Version: {config.versionNo} - {WebService.getBaseUrl()} */}
                      Version: {config.versionNo}
                    </h4>
                  </div>
                </td>
                <td>
                  <div className="headerCell">{logoutButton}</div>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>{sleepContact}</td>
                <td style={{ textAlign: "center" }}>{therapyContact}</td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default PageHeader;
