var numericUtility = {

    checkDecimal: function (fieldName, value, precision, scale) {

        var result = this.checkPrecision(fieldName, value, precision)

        if (result.valid) {

            return this.checkScale(fieldName, value, scale)
        }

        return result;
    },

    CheckNumeric: function(value, precision, scale) {

        var result = {valid: true, message: '' };

        return result;
    },

    checkPrecision: function (fieldName, value, precision) {

        var result = {valid: true, message: '' };
        var decimalLocation = value.indexOf('.');
        var valueLen = value.length;

        if (decimalLocation > 0)
            valueLen--;

        if (valueLen > precision) {
            var message = fieldName + " only accepts " + precision + " digits (total) to the left and right of the decimal."
            result = { valid: false, message: message };
        }

        return result;
    },

    checkScale(fieldName, value, scale) {

        var result = true;
        var decimalLocation = value.indexOf('.');

        if (decimalLocation > 0) {

            var decScale = value.substring(decimalLocation + 1);

            if (decScale.length > scale) {
                var message = fieldName + " only accepts precision to " + scale + " decimal places."
                result = { valid: false, message: message };
            }
        }

        return result;
    }
};

export default numericUtility;
