import React                                    from 'react';
import CreateReactClass                         from 'create-react-class';
import SA                                       from 'sweetalert2';

import therapy                                  from '../../services/therapy-service';

import img                                      from './images/delete.png';

//export default React.createClass({
var TherapyAttachmentDelete = CreateReactClass({

    getInitialState: function() {

      return {
          id: this.props.value
      };
    },

    onDeleteClick: function (e) {
        e.preventDefault();

        var therapyAttachmentId = this.state.id;
        var onDeleteRow = this.props.onDeleteRow;

        // SA({

        //     title: "Confirm",
        //     text: "Delete selected attachment?",
        //     showCancelButton: true,
        //     confirmButtonText: "Delete",
        //     closeOnConfirm: false,
        //     closeOnCancel: false
        // },
        // function(isConfirm) {

        //     if (isConfirm) {

                therapy.deleteTherapyAttachment(therapyAttachmentId).then((resp) => {

                    if (resp.data.success) {

                        onDeleteRow(therapyAttachmentId);
                        //SA({title: "", text: resp.data.message});
                        return true;
                    } else {

                        SA({title: "", text: resp.data.message});
                        return false;
                    }
                });

        //     } else {

        //         //SA({title: "", text: "Attachment delete cancelled."});
        //         return false;
        //     }
        // })
    },

    render: function () {

        return (
            <img src={img} title="Delete Attachment" alt="delete" onClick={this.onDeleteClick} height="32" width="32" />
        )
    }
})

export default TherapyAttachmentDelete
