import React                                    from 'react'
import { Redirect }                             from 'react-router-dom';
//import { withRouter }                           from 'react-router';

import authService                              from '../../services/auth-service';

import './css/logout.css';

class Logout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedOut: false
        }

        this.logout = this.logout.bind(this);
    }

    logout(evt) {
        evt.preventDefault();

        authService.logout();

        this.setState({
            loggedOut: true
        });
    }

    render() {
        
        if (this.state.loggedOut === true) {
            return <Redirect to="/login" />;
        }
        
        var user = authService.getUser();

        return (
            <div style={{ color: 'white', background: '#54007F' }}>
                <table style={{ marginRight: '20px' }}>
                    <tbody>
                        <tr>
                            <td>
                                <div className="headerCell" style={{ color: 'white', background: '#54007F', marginTop: '10px' }} >
                                Welcome: {user.firstName}
                                </div>
                            </td>
                            <td>
                                <ul className="nav navbar-nav navbar-right">
                                    <li className="menuOption"><a className="menuOption"  href="/login" onClick={this.logout}>Logout</a></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Logout