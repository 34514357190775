import React                            from 'react';
import Modal                            from 'react-modal';
import CreateReactClass                 from 'create-react-class';

import therapy                          from '../../services/therapy-service';

/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
  content : {
    top                 : '50%',
    left                : '50%',
    width               : '800px',
    height              : '655px',
    marginleft          : 'auto',
    marginRight         : 'auto',
    transform           : 'translate(-50%, -50%)',
    background          : 'white',
    borderColor         : 'black'
  }
};

//export default React.createClass({
var TherapyContractEdit = CreateReactClass({

  getInitialState: function() {
    return {
        modalIsOpen: false,
        contact: {}
    };
  },

  componentDidMount: function() {

    //this.TitleControl.autofocus();
  },

  openModal: function() {

    therapy.getTherapyContact(this.props.id).then(function(response) {

        this.setState({
            modalIsOpen: true,
            contact: response.data
        });

    }.bind(this));
  },

  afterOpenModal: function() {
    // references are now sync'd and can be accessed.
  },

  onCloseModal: function() {

    this.setState({
        modalIsOpen: false
    });
  },

    onSaveClick: function () {

        var therapyId = this.props.id;
        var title = this.TitleControl.value;
        var homeNumber = this.HomeNumberControl.value;
        var mobileNumber = this.MobileNumberControl.value;
        var otherNumber = this.WorkNumberControl.value;
        var streetNumber = this.StreetNumberControl.value;
        var streetName = this.StreetNameControl.value;
        var streetType = this.StreetTypeControl.value;
        var suburb = this.SuburbControl.value;
        var postcode = this.PostcodeControl.value;
        var email = this.EmailControl.value;

        therapy.updateTherapyContact(
           therapyId,
           title,
           homeNumber,
           mobileNumber,
           otherNumber,
           streetNumber,
           streetName,
           streetType,
           suburb,
           postcode,
           email);

        this.setState({
            modalIsOpen: false
        });
   },

  render: function() {
    var contact = this.state.contact;

    return (
      <div>
        <img src={this.props.theImage} title="Display contact details" alt="Display contact details" onClick={this.openModal} height="36" width="36" />

        <Modal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} style={customStyles}>

            <div>
                <div>
                    <div className="panel panel-default">
                        <div className="panel-body title-panel">
                            <div className="header">
                                <img src={this.props.theImage} alt="Contact Details" onClick={this.openModal} />
                                <h3>Patient Contact Details</h3>
                                <h4 className="bold-text">{contact.patientName} - DOB: {contact.dob}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <br />
                    <form className="form-horizontal">

                        <div className="form-group row">
                            <label for="TitleControl" className="col-sm-3 control-label">Patient Title :</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control form-control-sm" id="TitleControl" ref={(c) => {this.TitleControl = c;}} placeholder="Patient Title :" maxLength="10" defaultValue={contact.patientTitle} autoFocus tab="1" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="HomeNumberControl" className="col-sm-3 control-label">Home Number :</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control form-control-sm" id="HomeNumberControl" ref={(c) => {this.HomeNumberControl = c;}} placeholder="Home Number" maxLength="20" defaultValue={contact.patientHomePhone} tab="2" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="WorkNumberControl" className="col-sm-3 control-label">Work Number :</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control form-control-sm" id="WorkNumberControl" ref={(c) => {this.WorkNumberControl = c;}} placeholder="Work Number" maxLength="20" defaultValue={contact.patientOtherNumber} tab="3" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="MobileNumberControl" className="col-sm-3 control-label">Mobile Number :</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control form-control-sm" id="MobileNumberControl" ref={(c) => {this.MobileNumberControl = c;}} placeholder="Mobile Number" maxLength="20" defaultValue={contact.patientMobileNumber} tab="4" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="StreetNumberControl" className="col-sm-3 control-label">Street Number :</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control form-control-sm" id="StreetNumberControl" ref={(c) => {this.StreetNumberControl = c;}} placeholder="Street Number" maxLength="20" defaultValue={contact.patientStreetNumber} tab="5" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="StreetNameControl" className="col-sm-3 control-label">Street Name :</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control form-control-sm" id="StreetNameControl" ref={(c) => {this.StreetNameControl = c;}} placeholder="Street Name" maxLength="100" defaultValue={contact.patientStreetName} tab="6" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="StreetTypeControl" className="col-sm-3 control-label">Street Type :</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control form-control-sm" id="StreetTypeControl" ref={(c) => {this.StreetTypeControl = c;}} placeholder="Street Type" maxLength="20" defaultValue={contact.patientStreetType} tab="7" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="SuburbControl" className="col-sm-3 control-label">Suburb :</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control form-control-sm" id="SuburbControl" ref={(c) => {this.SuburbControl = c;}} placeholder="Suburb" maxLength="50" defaultValue={contact.patientSuburb} tab="8" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="PostcodeControl" className="col-sm-3 control-label">Postcode :</label>
                            <div className="col-sm-9">
                                <input type="string" className="form-control form-control-sm" id="PostcodeControl" ref={(c) => {this.PostcodeControl = c;}} placeholder="Postcode" maxLength="50" defaultValue={contact.patientPostcode} tab="9" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="EmailControl" className="col-sm-3 control-label">Email :</label>
                            <div className="col-sm-9">
                                <input type="email" className="form-control form-control-sm" id="EmailControl" ref={(c) => {this.EmailControl = c;}} placeholder="EMail" maxLength="320" defaultValue={contact.patientEmail} tab="10" />
                            </div>
                        </div>

                        <div className="btn-toolbar pull-right" data-toggle="buttons">
                            <button type="button" className="btn btn-primary btn-sm ss-button" onClick={this.onSaveClick} tab="11">OK</button>
                            <button type="button" className="btn btn-secondry btn-sm ss-button" onClick={this.onCloseModal} tab="12">Close</button>
                        </div>

                    </form>
                </div>
            </div>
        </Modal>
    </div>
    );
}
});

export default TherapyContractEdit