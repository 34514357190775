import React from "react";
import Modal from "react-modal";
import CreateReactClass from "create-react-class";
import ReactDataGrid from "react-data-grid";
import SAlert from "sweetalert2";

import GridHeader from "../headers/tall-grid-header";
import GridVerticalHeader from "../headers/therapy-script-vertical-header";

import TherapyScriptDelete from "./therapy-script-delete";
import TherapyScriptPrint from "./therapy-script-print";
import RowRenderer from "./therapy-script-table-row-renderer";

import CheckboxFormatter from "../../controls/checkbox-formatter";

import AuthService from "../../services/auth-service";
import Reports from "../../services/report-service";
import Therapy from "../../services/therapy-service";
import ScriptValidationService from "../../services/script-validation-service";

import logo from "./images/scripts.png";

import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../css/login.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "740px",
    height: "690px",
    marginleft: "auto",
    marginRight: "auto",
    transform: "translate(-50%, -50%)",
    background: "white",
    borderColor: "black"
  }
};

export default CreateReactClass({
  getInitialState() {
    return {
      rows: [],
      modalIsOpen: false,
      script: {},
      info: {}
    };
  },

  componentDidMount() {
    Therapy.getTherapyInfo(this.props.therapyId).then(
      function(info) {
        this.setState({
          info: info.data[0]
        });
      }.bind(this)
    );
  },

  onDeleteRow(therapyScriptID) {
    var data = this.state.rows;
    var newData = [];
    var i = -1;
    var j = -1;

    newData.length = data.length;

    if (newData.length === 0) {
      return;
    }

    while (i < newData.length - 1) {
      i++;

      console.log("Loop i=" + i + " j=" + j);
      console.log(
        "Loop id=" + data[i].therapyScriptID + " id=" + therapyScriptID
      );

      if (data[i].therapyScriptID === therapyScriptID) {
        i++;
      }

      if (i < data.length) {
        j++;

        console.log("Loop i=" + i + " j=" + j);
        console.log("data[" + i + "]=" + data[i]);
        console.log("newData[" + j + "]=" + newData[j]);

        newData[j] = data[i];
      }
    }

    newData.length = j + 1;

    this.setState({
      rows: newData
    });
  },

  onEntryDateClicked(e) {
    alert(e);
  },

  buildColumns() {
    var columnWidth = 50;
    var columns = [];
    var user = AuthService.getUser();

    if (user.internal) {
      columns.push({
        key: "therapyScriptID",
        name: "",
        editable: false,
        width: 45,
        editor: <TherapyScriptDelete onDeleteRow={this.onDeleteRow} />,
        formatter: <TherapyScriptDelete onDeleteRow={this.onDeleteRow} />
      });
    }

    columns.push({
      key: "printId",
      name: "",
      editable: false,
      width: 45,
      formatter: <TherapyScriptPrint />
    });

    columns.push({
      key: "entryDate",
      name: "Entered On",
      headerRenderer: <GridHeader text="Entered On" />,
      resizable: true,
      editable: false,
      onCellClick: this.onCellClick,
      width: 150
    });

    columns.push({
      key: "diagnosticAHI",
      name: "Diagnostic AHI",
      headerRenderer: <GridVerticalHeader text="Diagnostic AHI" left="90px" />,
      width: columnWidth,
      resizable: true,
      editable: false
    });

    columns.push({
      key: "postTherapyAHI",
      name: "Post Therapy AHI",
      headerRenderer: (
        <GridVerticalHeader text="Post Therapy AHI" left="90px" />
      ),
      width: columnWidth,
      resizable: true,
      editable: false
    });

    columns.push({
      key: "preTherapyESS",
      name: "Pre Therapy ESS",
      headerRenderer: <GridVerticalHeader text="Pre Therapy ESS" left="90px" />,
      width: columnWidth,
      resizable: true,
      editable: false
    });

    columns.push({
      key: "postTherapyESS",
      name: "Post Therapy ESS",
      headerRenderer: (
        <GridVerticalHeader text="Post Therapy ESS" left="90px" />
      ),
      width: columnWidth,
      resizable: true,
      editable: false
    });

    columns.push({
      key: "symptomaticImprovementScore",
      name: "Symptomatic Improvement Score",
      headerRenderer: (
        <GridVerticalHeader
          text="Symptomatic<br/>Improvement Score"
          left="72px"
        />
      ),
      width: columnWidth,
      resizable: true,
      editable: false
    });

    columns.push({
      key: "averageDailyUsageHours",
      name: "Average Daily Usage Hours",
      headerRenderer: (
        <GridVerticalHeader text="Average Daily<br/>Usage Hours" left="72px" />
      ),
      width: columnWidth,
      resizable: true,
      editable: false
    });

    columns.push({
      key: "currentMask",
      name: "Current Mask",
      headerRenderer: <GridVerticalHeader text="Current Mask" left="90px" />,
      width: columnWidth,
      resizable: true,
      editable: false
    });

    columns.push({
      key: "currentMachinePressureSettings",
      name: "Current Machine Pressure Settings",
      headerRenderer: (
        <GridVerticalHeader
          text="Current Machine<br/>Pressure Settings"
          left="72px"
        />
      ),
      width: columnWidth,
      resizable: true,
      editable: false
    });

    columns.push({
      key: "physicianOverride",
      name: "Physician Override",
      headerRenderer: (
        <GridVerticalHeader text="Physician Override" left="90px" />
      ),
      width: columnWidth,
      resizable: true,
      editable: false,
      formatter: <CheckboxFormatter />
    });

    columns.push({
      key: "note",
      name: "Note",
      headerRenderer: <GridHeader text="Notes" />,
      width: 430,
      resizable: true,
      editable: true
    });

    return columns;
  },

  onCellSelected: function(coordinates) {
    if (coordinates.idx !== 2)
      this.grid.openCellEditor(coordinates.rowIdx, coordinates.idx);
  },

  onAfterOpenModal() {
    var script = this.state.script;

    this.DiagnosticAhiControl.value = script.diagnosticAHI;
    this.PostTherapyAhiControl.value = script.postTherapyAHI;
    this.PreTherapyEssControl.value = script.preTherapyESS;
    this.PostTherapyEssControl.value = script.postTherapyESS;
    this.SymptomaticImprovementScoreControl.value =
      script.symptomaticImprovementScore;
    this.AverageDailyUsageControl.value = script.averageDailyUsageHours;
    this.CurrentMaskControl.value = script.currentMask;
    this.CurrentMachinePressureSettingsControl.value =
      script.currentMachinePressureSettings;
    this.PhysicianOverrideControl.checked = script.physicianOverride;
    this.NoteControl.value = script.note;
  },

  onOpenModal() {
    this.setState({
      modalIsOpen: true
    });
  },

  onAddNewCloseModal() {
    this.setState({
      modalIsOpen: false
    });
  },

  onAddNewPrintClick(isPrint) {
    //var resp = ScriptValidationService.validateScript(true);
    let resp = ScriptValidationService.validateScript({
      diagnosticAHI: Number(this.DiagnosticAhiControl.value),
      postTherapyAHI: Number(this.PostTherapyAhiControl.value),
      preTherapyESS: Number(this.PreTherapyEssControl.value),
      postTherapyESS: Number(this.PostTherapyEssControl.value),
      symptomaticImprovementScore: Number(
        this.SymptomaticImprovementScoreControl.value
      ),
      averageDailyUsageHours: Number(this.AverageDailyUsageControl.value),
      currentMask: this.CurrentMaskControl.value,
      currentMachinePressureSettings: this.CurrentMachinePressureSettingsControl
        .value,
      physicianOverride: this.PhysicianOverrideControl.checked,
      note: this.NoteControl.value,
      isPrint: isPrint
    });

    var self = this;

    if (resp.valid === false) {
      SAlert({
        title: "Validation",
        html: resp.message,
        type: "error",
        showCloseButton: true,
        width: 900
        // confirmButtonText: 'Yes',
        // showCancelButton: true,
        // cancelButtonText: 'No'
      }).then(function(result) {
        if (result) {
          return;
        } else {
          SAlert({
            title: "Create New Script",
            html:
              "You need to create the new script before you can print. Do you wish to continue?",
            type: "question",
            showCloseButton: true,
            confirmButtonText: "Continue - Create script",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            width: 900
          }).then(function(result) {
            self.saveScriptCopy().then(function() {
              var ele = document.getElementById("downloadFrame");

              if (ele) {
                document.body.removeChild(ele);
              }

              var downloadFrame = document.createElement("iframe");
              downloadFrame.id = "downloadFrame";
              downloadFrame.src = Reports.makeTherapyScriptUrl(
                self.state.script.therapyScriptId,
                "PDF"
              );
              downloadFrame.className = "hidden";
              document.body.appendChild(downloadFrame);

              self.setState({
                modalIsOpen: false
              });
            });
          });

          self.setState({
            modalIsOpen: false
          });
        }
      });
    } else {
      SAlert({
        title: "Create New Script",
        html:
          "You need to create the new script before you can print. Do you wish to continue?",
        showCloseButton: true,
        confirmButtonText: "Continue",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        width: 700
      }).then(function(result) {
        self.saveScriptCopy().then(function(result) {
          var ele = document.getElementById("downloadFrame");

          if (ele) {
            document.body.removeChild(ele);
          }

          var downloadFrame = document.createElement("iframe");
          downloadFrame.id = "downloadFrame";
          downloadFrame.src = Reports.makeTherapyScriptUrl(
            self.state.script.therapyScriptId,
            "PDF"
          );
          downloadFrame.className = "hidden";
          document.body.appendChild(downloadFrame);

          self.setState({
            modalIsOpen: false
          });
        });
      });
    }
  },

  onAddNewSaveClick(e) {
    //var resp = this.validateScript(false);
    let resp = ScriptValidationService.validateScript({
      diagnosticAHI: Number(this.DiagnosticAhiControl.value),
      postTherapyAHI: Number(this.PostTherapyAhiControl.value),
      preTherapyESS: Number(this.PreTherapyEssControl.value),
      postTherapyESS: Number(this.PostTherapyEssControl.value),
      symptomaticImprovementScore: Number(
        this.SymptomaticImprovementScoreControl.value
      ),
      averageDailyUsageHours: Number(this.AverageDailyUsageControl.value),
      currentMask: this.CurrentMaskControl.value,
      currentMachinePressureSettings: this.CurrentMachinePressureSettingsControl
        .value,
      physicianOverride: this.PhysicianOverrideControl.checked,
      note: this.NoteControl.value,
      isPrint: false
    });

    if (resp.valid === false) {
      SAlert({
        title: "<b>Validation</b>",
        html: resp.message,
        type: "error",
        width: 900
      });
      return;
    }

    SAlert({
      title: "Create New Script",
      html: "Do you wish to create a new script",
      type: "question",
      showCancelButton: true,
      confirmButtonText: "YES Create script.",
      cancelButtonText: "NO Do not create.",
      closeOnConfirm: true,
      closeOnCancel: true,
      width: 900
    }).then(
      function(isConfirm) {
        if (isConfirm) {
          this.saveScriptCopy();
        }
      }.bind(this)
    );
  },

  saveScriptCopy() {
    let self = this;

    return new Promise(
      (resolve, reject) => {
        var diagnosticAhi = self.DiagnosticAhiControl.value;
        var postTherapyAhi = self.PostTherapyAhiControl.value;
        var preTherapyEss = self.PreTherapyEssControl.value;
        var postTherapyEss = self.PostTherapyEssControl.value;
        var symptomaticImprovementScore =
          self.SymptomaticImprovementScoreControl.value;
        var averageDailyUsage = self.AverageDailyUsageControl.value;
        var currentMask = self.CurrentMaskControl.value;
        var currentMachinePreasureSettings =
          self.CurrentMachinePressureSettingsControl.value;
        var physicianOverride = self.PhysicianOverrideControl.checked;
        var note = self.NoteControl.value;

        Therapy.addTherapyScript(
          self.state.script.therapyID,
          diagnosticAhi,
          postTherapyAhi,
          preTherapyEss,
          postTherapyEss,
          symptomaticImprovementScore,
          averageDailyUsage,
          currentMask,
          currentMachinePreasureSettings,
          physicianOverride,
          note
        ).then(
          function(resp) {
            this.props.refreshData();

            self.setState({
              modalIsOpen: false,
              script: resp.data
            });

            resolve(true);
          }.bind(self)
        );
      },
      function(dismiss) {}
    );
  },

  rowGetter(rowIdx) {
    return this.state.rows[rowIdx];
  },

  updateRow(update) {
    Therapy.updateTherapyScript(update.therapyScriptID, update.note).then(
      function(resp) {
        if (resp.data === "") return true;

        return false;
      }
    );
  },

  handleRowUpdated(e) {
    //merge updated row with current row and rerender by setting state
    var rows = this.state.rows;
    Object.assign(rows[e.rowIdx], e.updated);

    if (this.updateRow(rows[e.rowIdx]) === true) {
      this.setState({
        rows: rows
      });

      return;
    }
  },

  refreshTable(newRows) {
    this.setState({
      rows: []
    });

    this.setState({
      rows: newRows
    });
  },

  onCellClick(row) {
    //var rows = this.state.rows;

    this.onOpenModal(row);

    this.setState({
      script: row,
      modalIsOpen: true
    });
  },

  render() {
    let title;
    let columns = this.buildColumns();

    if (this.state.info !== undefined)
      title = (
        <h3>
          Copy Therapy Script:{" "}
          {this.state.info.surname + ", " + this.state.info.firstName}
        </h3>
      );
    else title = <h3>Copy Therapy Script</h3>;

    var rowRenderer = <RowRenderer onCellClick={this.onCellClick} />;

    return (
      <div>
        <ReactDataGrid
          enableCellSelect={true}
          columns={columns}
          rowGetter={this.rowGetter}
          rowsCount={this.state.rows.length}
          headerRowHeight={140}
          minHeight={400}
          onRowUpdated={this.handleRowUpdated}
          rowRenderer={rowRenderer}
          tabIndex="61"
        />

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.onAfterOpenModal}
          style={customStyles}
        >
          <div>
            <div className="panel panel-default">
              <div className="panel-body title-panel">
                <div className="header">
                  <img
                    src={logo}
                    title="Add Therapy Patient"
                    alt="Add Therapy Patient"
                    onClick={this.openModal}
                  />
                  {title}
                </div>
              </div>
            </div>

            <br />

            <form className="form-horizontal">
              <div className="form-group row">
                <label
                  for="DiagnosticAhiControl"
                  className="col-sm-6 control-label"
                >
                  Diagnostic AHI :
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="DiagnosticAhiControl"
                    ref={c => {
                      this.DiagnosticAhiControl = c;
                    }}
                    placeholder="Number"
                    autoFocus
                    tab="1"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="PostTherapyAhiControl"
                  className="col-sm-6 control-label"
                >
                  Post Therapy AHI :
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="PostTherapyAhiControl"
                    ref={c => {
                      this.PostTherapyAhiControl = c;
                    }}
                    placeholder="Number"
                    tab="2"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="PreTherapyEssControl"
                  className="col-sm-6 control-label"
                >
                  Pre Therapy ESS :
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="PreTherapyEssControl"
                    ref={c => {
                      this.PreTherapyEssControl = c;
                    }}
                    placeholder="Number"
                    tab="3"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="PostTherapyEssControl"
                  className="col-sm-6 control-label"
                >
                  Post Therapy ESS :
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="PostTherapyEssControl"
                    ref={c => {
                      this.PostTherapyEssControl = c;
                    }}
                    placeholder="Number"
                    tab="4"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="SymptomaticImprovementScoreControl"
                  className="col-sm-6 control-label"
                >
                  Symptomatic Improvement Score :
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="SymptomaticImprovementScoreControl"
                    ref={c => {
                      this.SymptomaticImprovementScoreControl = c;
                    }}
                    placeholder="Number"
                    tab="5"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="AverageDailyUsageControl"
                  className="col-sm-6 control-label"
                >
                  Average Daily Usage (Hours) :
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="AverageDailyUsageControl"
                    ref={c => {
                      this.AverageDailyUsageControl = c;
                    }}
                    placeholder="Number"
                    tab="6"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="CurrentMaskControl"
                  className="col-sm-6 control-label"
                >
                  Current Mask :
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="CurrentMaskControl"
                    ref={c => {
                      this.CurrentMaskControl = c;
                    }}
                    placeholder="Current Mask"
                    tab="7"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="CurrentMachinePressureSettingsControl"
                  className="col-sm-6 control-label"
                >
                  Current Machine Pressure Settings :
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="CurrentMachinePressureSettingsControl"
                    ref={c => {
                      this.CurrentMachinePressureSettingsControl = c;
                    }}
                    placeholder="Current Machine Pressure Settings"
                    tab="8"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="PhysicianOverrideControl"
                  className="col-sm-6 control-label"
                >
                  Physician Override :
                </label>
                <div className="col-sm-6">
                  <input
                    type="checkbox"
                    className="form-control"
                    id="PhysicianOverrideControl"
                    ref={c => {
                      this.PhysicianOverrideControl = c;
                    }}
                    placeholder="Physician Override"
                    tab="9"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label for="NoteControl" className="col-sm-2 control-label">
                  Notes :
                </label>
                <div className="col-sm-10">
                  <textarea
                    className="form-control"
                    id="NoteControl"
                    ref={c => {
                      this.NoteControl = c;
                    }}
                    placeholder="Notes"
                    tab="10"
                  />
                </div>
              </div>

              <div className="btn-toolbar pull-right" data-toggle="buttons">
                <button
                  type="button"
                  className="btn btn-primary btn-sm ss-button"
                  onClick={this.onAddNewPrintClick}
                  tab="62"
                >
                  Print
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm ss-button"
                  onClick={this.onAddNewSaveClick}
                  tab="63"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm ss-button"
                  onClick={this.onAddNewCloseModal}
                  tab="64"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
});
