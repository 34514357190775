import React                            from 'react';
import CreateReactClass                 from 'create-react-class';
import ReactDataGrid                    from 'react-data-grid';

// -------------------------------------------------------------------------------------------------
//  CellRenderer
// -------------------------------------------------------------------------------------------------
var cellRenderer = CreateReactClass({

    getInitialState : function() {

        return {
            key: Math.random()
        }
    },

    onKeyDown: function(e) {

        if (this.props.column.key === 'note') {

            if (e.keyCode === 13)
                this.props.column.openModal( this.props.rowIdx );
        }
    },

    onClick: function(cell) {

        if (this.props.column.key === 'note') {

            this.props.column.openModal( this.props.rowIdx );
        }
    },

    render() {
        return <div key={this.state.key} onClick={this.onClick} onKeyDown={this.onKeyDown} ><ReactDataGrid.Cell id="theCell" ref={(c) => {this.theCell = c;}} {...this.props} openModal={this.props.openModal} /></div>
    }
});

// -------------------------------------------------------------------------------------------------
//  RowRenderer
// -------------------------------------------------------------------------------------------------
export default CreateReactClass({

    setScrollLeft: function(scrollBy) {
        //if you want freeze columns to work, you need to make sure you implement this as a pass through
        this.theRow.setScrollLeft(scrollBy);
    },

    getRowStyle: function() {
        return {
            color: this.getRowBackground()
        }
    },

    getRowBackground: function() {
        return this.props.row.partnerAdded ?  'blue' : 'black'
    },

    render: function() {
        //here we are just changing the style
        //but we could replace this with anything we liked, cards, images, etc
        //usually though it will just be a matter of wrapping a div, and then calling back through to the grid
        //return (<div style={this.getRowStyle()}><ReactDataGrid.Row ref="row" {...this.props} /></div>)

        //var cellRenderer = <CellRenderer openModal={this.props.openModal} {...this.props} />;

        return (<div style={this.getRowStyle()}><ReactDataGrid.Row id="theRow" ref={(c) => {this.theRow = c;}} {...this.props} cellRenderer={cellRenderer} /></div>)
    }
});
