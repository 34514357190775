import React                            from 'react';
import CreateReactClass                 from 'create-react-class';

import TherapyNoteDisplay               from '../notes/therapy-note-display';
import TherapyScriptDisplay             from '../scripts/therapy-script-display';
import TherapyContactEdit               from '../contacts/therapy-contact-edit';
import TherapyAttachmentDisplay         from '../attachments/therapy-attachment-display';

import attachment                       from './images/attachments.png';
import notes                            from './images/clipboard.png';
import script                           from './images/script.png';
import contact                          from './images/contact.png';

export default CreateReactClass({

    onAttachmentClick: function () {
        alert('Attachment: ' + this.props.value)
    },

    onAddNoteClick: function (evt) {
        evt.preventDefault();
        alert('Add Note: ' + this.props.value)
    },

    onContactClick: function () {
        alert('Contact: ' + this.props.value)
    },

    onDisplayNoteClick: function () {
        alert('Display Notes: ' + this.props.value)
    },

    onDisplayScriptClick: function () {
        alert('Display Scripts: ' + this.props.value)
    },

    onScriptClick: function () {
        alert('Script: ' + this.props.value)
    },

    render: function () {
        return (
            <div className="button-cell">
                <TherapyNoteDisplay id={this.props.value} theImage={notes} onClick={this.onDisplayNoteClick} />
                <TherapyScriptDisplay id={this.props.value} theImage={script}  onClick={this.onDisplayScriptClick} />
                <TherapyContactEdit id={this.props.value} theImage={contact} />
                <TherapyAttachmentDisplay id={this.props.value} theImage={attachment} />
            </div>
        )
    }
})
