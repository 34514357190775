import React                            from 'react';
import PropTypes                        from 'prop-types';
import Moment                           from 'moment';

const { editors: { EditorBase } } = require('react-data-grid');

export default class ParamsEditor extends EditorBase {

    constructor(props) {
        super(props);

        this.state = {
            value : this.props.value,
        };

        this._input = null;
        this.getInputNode = this.getInputNode.bind(this);
        this.getValue = this.getValue.bind(this);

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    getInputNode() {
        return this._input;
    }

    getValue() {
        var updated = {};
        updated[this.props.column.key] = this.state.value;
        return updated;
    }

    onValueChanged(ev) {

        this.setState({
            value: Moment(ev.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
        });        
    }

    render() {

        let theDate = Moment(this.state.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        return (
        <div>
            <input type="date" ref={(ref) => { this._input = ref; }} value={theDate} onChange={this.onValueChanged} ></input>
        </div>);
    }
}

ParamsEditor.propTypes = {
    column: PropTypes.any,
};