var utility = {

    detectIE() {
        var ua = window.navigator.userAgent;
        var ie = ua.search(/(MSIE|Trident|Edge)/);
    
        return ie > -1;
    },

}

export default utility;