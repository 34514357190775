import React                            from 'react';
import ClassNames                       from 'classnames';

class UserNameField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            errorMessage: '',
            isValid: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
    }

    handleChange(event) {
        var curVal = event.target.value;
        this.setState({ value: curVal }, function() {
            this.validate();
        });
    }

    validate() {
        var valid = true,
            msg = '',
            curVal = this.state.value;

        if (!curVal || curVal.length === 0) {
            msg = 'Username required';
            valid = false;
        }
        else if (curVal.indexOf('@') >= 0 && !curVal.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)) {
            msg = 'Invalid email address';
            valid = false;
        }

        this.setState({
            errorMessage: msg,
            isValid: valid
        });
    }

    render() {
        var emailClassNames = ClassNames({
            'form-group': true,
            'has-error': this.state.isValid === false,
            'has-success': this.state.isValid === true
        });

        return (
            <div className={emailClassNames}>
                <label className="control-label" htmlFor="inputUser">User name</label>
                <input
                    type="text"
                    id="inputUser"
                    className="form-control"
                    placeholder="User name"
                    value={this.state.value}
                    onChange={this.handleChange}
                    autoFocus />
                <span className="help-block">{this.state.errorMessage}</span>
            </div>
        );
    }
}

export default UserNameField;
