import React from "react";
import ReactDOM from "react-dom";
import CreateReactClass from "create-react-class";
import PropTypes from "prop-types";

//export default React.createClass({
const CheckboxEditor = CreateReactClass({
  //class CheckboxEditor extends React.Component {
  getInitialState() {
    const v = this.props.value;

    return {
      value: v
    };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  },

  getInputNode() {
    return ReactDOM.findDOMNode(this);
  },

  handleOnChange(args) {
    this.setState({
      value: args.target.checked
    });
  },

  getValue() {
    let updated = {};
    updated[this.props.column.key] = this.state.value;
    return updated;
  },

  render() {
    // console.log('render checkbox editor with value ' + this.state.value);

    return (
      <input
        type="checkbox"
        checked={this.state.value}
        onChange={this.handleOnChange}
        width="15px"
      />
    );
  }
});

CheckboxEditor.propTypes = {
  value: PropTypes.bool
};

export default CheckboxEditor;
