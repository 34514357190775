import React                            from 'react';
import ReactDataGrid                    from 'react-data-grid';

class StudyUploadTableRowRenderer extends React.Component {  
    
    setScrollLeft (scrollBy) {
        //if you want freeze columns to work, you need to make sure you implement this as apass through
        this.theRow.setScrollLeft(scrollBy);
    }

    getRowStyle () {
        return {
            color: this.getRowBackground()
        }
    }

    getRowBackground () {
        return this.props.row.isValid ?  'black' : 'red'
    }

    render () {
        //here we are just changing the style
        //but we could replace this with anything we liked, cards, images, etc
        //usually though it will just be a matter of wrapping a div, and then calling back through to the grid
        //return (<div style={this.getRowStyle()}><ReactDataGrid.Row ref="row" {...this.props} /></div>)

        return (<div style={this.getRowStyle()}><ReactDataGrid.Row id="theRow" ref={(c) => {this.theRow = c;}} {...this.props} /></div>)
    }
};

export default StudyUploadTableRowRenderer