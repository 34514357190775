import React                            from 'react';
import Modal                            from 'react-modal';
import CreateReactClass                 from 'create-react-class';
import SA                               from 'sweetalert2';

import auth                             from '../../services/auth-service';
import Therapy                          from '../../services/therapy-service';

import logo                             from './images/clipboard.png';

/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
  content : {
    top                 : '50%',
    left                : '50%',
    width               : '800px',
    height              : '535px',
    marginLeft          : 'auto',
    marginRight         : 'auto',
    transform           : 'translate(-50%, -50%)',
    background          : 'white',
    borderColor         : 'black'
  }
};

export default CreateReactClass({

    getInitialState: function() {

        return {
            modalIsOpen: this.props.openAddNew,
            followUpDate: null
        };
    },

    onOpenModal: function() {

        Therapy.getTherapyInfo( this.props.id ).then(function(info) { 
            
            this.setState({

                modalIsOpen: true,
                info: info.data[0]
            });
       }.bind(this))
    },

    onCloseModal: function() {
        this.setState({
            modalIsOpen: false
        });
    },

    onSaveClick: function (e) {

        const therapyId = this.props.id;
        const note = this.NoteControl.value;
        let followUpDate;

        if (note === '' || note === undefined) {
            SA("Validation Error", "Cannot add a blank note.", "error");
            return;
        }

        // var checked = this.FollowUpControl.checked;
        const user = auth.getUser();

        if (followUpDate === "" || followUpDate === undefined) {
            followUpDate = null;
        }

        Therapy.addTherapyLog(
            therapyId, 
            note, 
            user.username, 
            user.id
        ).then(function(resp) {

            this.setState({
                modalIsOpen: false
            });

            this.props.refreshData();
        }.bind(this));
    },

    onFollowUpDateChange: function(date) {

        this.setState({
            followUpDate: date
        })
    },

    render: function() {

        let title;

        if ( this.state.info !== undefined )
            title = <h3>Therapy History: { this.state.info.surname + ', ' + this.state.info.firstName }</h3>;
        else
            title = <h3>Therapy History</h3>;
        
        return (
            <div className="form-group btn-toolbar">
                <button className="btn btn-primary btn-sm ss-button" onClick={this.onOpenModal} tabIndex="2" autoFocus >Add New</button>
                <button className="btn btn-secondry btn-sm ss-button" onClick={this.props.printClick} tabIndex="3" >Print</button>
                <button className="btn btn-secondry btn-sm ss-button" onClick={this.props.closeClick} tabIndex="4" >Close</button>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    style={customStyles} >

                    <div >
                        <div className="panel panel-default">
                            <div className="panel-body title-panel">
                                <div className="header">
                                    <img src={logo} title="Add Therapy Patient" alt="Add Therapy Patient" onClick={this.openModal} />
                                    { title }
                                </div>
                            </div>
                        </div>

                        <form className="form-horizontal">

                            <br />
                            <textarea className="form-control" 
                                      id="NoteControl" 
                                      style={{ resize: 'none' }} 
                                      rows="15"
                                      maxLength="2048" 
                                      ref={(c) => {this.NoteControl = c;}} 
                                      placeholder="Notes" 
                                      tabIndex="51" 
                                      autoFocus />

                            <br />

                            <div className="btn-toolbar pull-right" data-toggle="buttons">
                                <button type="button" className="btn btn-primary btn-sm ss-button" onClick={this.onSaveClick} tabIndex="52" >OK</button>
                                <button type="button" className="btn btn-secondry btn-sm ss-button" onClick={this.onCloseModal} tabIndex="53" >Close</button>
                            </div>

                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
});
