import React from "react";
//import CreateReactClass from "create-react-class";

//import "./css/wrap-text.css";

//export default CreateReactClass({

export default class WrapTextFormatter extends React.Component {
  render() {
    const wrapStyle1 = {
      whiteSpace: "pre",
      wordBreak: "break-all",
      wordWrap: "break-word",
      background: "lightgreen",
      height: "'" + this.props.height + "px",
      width: "'150px'"
    };

    return <div style={wrapStyle1}>{this.props.value}</div>;
  }
}
