import React                            from 'react';
import CreateReactClass                 from 'create-react-class';
//import SAlert                           from 'sweetalert2';

//import Therapy                          from '../../modules/therapy';
import Referrals                        from '../../services/referral-service';

import Logo                             from './images/delete.png';

export default CreateReactClass({

    getInitialState () {

      return {
          id: this.props.value
      };
    },

    onDeleteClick (e) {
        e.preventDefault();
        
        var therapyReferralId = this.props.value;
        var onDeleteRow = this.props.onDeleteRow;

        // Therapy.deleteTherapyAttachment( therapyReferralId ).then((resp) => {

        //     if (resp.data.success) {

                onDeleteRow( therapyReferralId );
                Referrals.removeId( therapyReferralId );
        //     } else {

        //         SAlert({
        //             title: "Referral", 
        //             text: resp.data.message,
        //             type: "warning"
        //         });
        //     }
        // });
    },

    render () {

        return (
            <img src={Logo} alt="delete" onClick={this.onDeleteClick} height="24" width="24" />
        )
    }
})
