import React                            from 'react';
import CreateReactClass                 from 'create-react-class';

import reports                          from '../../services/report-service';

import img                              from './images/download.png';

//export default React.createClass({
const TherapyAttachmentViewer = CreateReactClass({

    onImageClick: function (evt) {
        evt.preventDefault();

        const ele = document.getElementById('downloadFrame');

        if (ele) {

            document.body.removeChild(ele);
        }

        const downloadFrame = document.createElement('iframe');
        downloadFrame.id = 'downloadFrame';
        downloadFrame.src = reports.getTherapyAttachment(this.props.value);
        //downloadFrame.style = 'display:none';
        downloadFrame.className = 'hidden';
        document.body.appendChild(downloadFrame);
    },

    render: function () {
        return (
            <div className="button-cell">
                <img id={this.props.value} src={img} alt={""} onClick={this.onImageClick}/>
            </div>
        )
    }
});

export default TherapyAttachmentViewer