import React                                                from 'react';
import { Route, Redirect }                                  from 'react-router-dom';

import authService                                       from '../services/auth-service';

const PrivateRoute = ({ component, ...rest }) => (

    <Route {...rest} render={(props) => (
        authService.loggedIn() ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={{
          pathname: '/login',
        }} />
      )
    )} />
  );

  export default PrivateRoute;
