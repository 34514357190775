import React                            from 'react';
import Modal                            from 'react-modal';
import CreateReactClass                 from 'create-react-class';

import TherapyNoteAdd                   from './therapy-note-add';
import TherapyNoteTable                 from './therapy-note-table';

import Therapy                          from '../../services/therapy-service';
import Reports                          from '../../services/report-service';

/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
    content : {
        top                 : '50%',
        left                : '50%',
        width               : '1200px',
        height              : '760px',
        marginLeft          : '10',
        marginRight         : '10',
        transform           : 'translate(-50%, -50%)',
        background          : 'white',
        borderColor         : 'black'
    }
};

export default CreateReactClass({

    getInitialState: function() {

        return {
            openAddNew: false,
            modalIsOpen: false,
            info: {}
        };
    },

    openModal: function() {

        Therapy.getTherapyInfo( this.props.id ).then( function(info) { 
            
            this.setState({

                info: info.data[0],
                modalIsOpen: true
            });
        }.bind(this))
    },

    componentDidMount: function() {

        console.log(this._dataTable);
    },

    loadData: function() {

        Therapy.getTherapyNotes(this.props.id).then(function(response) {
            this._dataTable.refreshTable(response.data)            
        }.bind(this));
    },
    
    afterOpenModal: function() {

        this.loadData();

        const openDlg = this._dataTable.grid.props.rowsCount === 0;

        //if (this._dataTable.grid.props.rowsCount === 0) {
            this.setState({
                openAddNew: openDlg
            })
        //}
    },

    onCloseModal: function() {

        this.setState({
            modalIsOpen: false
        });
    },

    onPrintReport: function() {

        const ele = document.getElementById('downloadFrame');

        if (ele) {
            document.body.removeChild(ele);
        }

        const downloadFrame = document.createElement('iframe',);
        downloadFrame.id = 'downloadFrame';
        downloadFrame.src = Reports.makeTherapyHistoryUrl(this.props.id, 'PDF');
        //downloadFrame.style = 'display:none';
        downloadFrame.className = 'hidden';
        document.body.appendChild(downloadFrame);
    },

    render: function() {

        let title;

        if ( this.state.info !== undefined )
            title = <h3>Therapy History: { this.state.info.surname + ', ' + this.state.info.firstName }</h3>;
        else
            title = <h3>Therapy History</h3>;

        return (
            <div>
                <img src={this.props.theImage} title="Display Notes" alt="Display Notes" onClick={this.openModal} height="36" width="36" />

                <Modal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal} style={customStyles} >

                    <div >
                        <div className="panel panel-default">
                            <div className="panel-body title-panel">
                                <div className="header">
                                    <img src={this.props.theImage} title="Display Notes" alt="Display Notes" onClick={this.openModal} height="24" width="24" />
                                    { title }
                                </div>
                            </div>
                        </div>
                        
                        <div style={{height: 100}}>

                            <br />
                            <TherapyNoteTable id="grid" ref={(dataTable) => {this._dataTable = dataTable}} />
                            <br />
                            
                            <div className="form-inline btn-toolbar" style={{height: 40}} >
                                <TherapyNoteAdd id={this.props.id} className="btn btn-primary btn-sm ss-button" refreshData={this.loadData} printClick={this.onPrintReport} closeClick={this.onCloseModal} openAddNew={this.state.openAddNew} />
                            </div>
                            
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
});
