import React                            from 'react';
import CreateReactClass                 from 'create-react-class';

export default CreateReactClass({

    render: function () {

        let style ={
            width: '130px', 
            height: this.props.left, 
            textAlign: 'right', 
            fontWeight: 'bold'
        }

        let text = this.props.text;
        let newText = text.split('<br/>').map(i => {
            return <span>{i}<br/></span>
        });

        return (
            <div className="grid-header-background">
                <div className="vertical-text" >

                    <div style={style}>
                    </div>
                    
                    <div className="vertical-header-text">
                        {newText}
                    </div>
                </div>
            </div>
        )
    }
});