import axios from "axios";
import querystring from "querystring";
import authService from "./auth-service";
import WebService from "./web-service";

class therapy extends WebService {
  static addTherapyLog(
    therapyId,
    note,
    enteredBy,
    staffId,
    checked,
    followUpDate
  ) {
    const params = {
      therapyId: therapyId,
      note: note,
      EnteredBy: enteredBy,
      staffId: staffId,
      followUp: checked,
      followUpDate: followUpDate ? followUpDate.format("DD/MM/YYYY") : null
    };

    return axios.post(
      "api/AddTherapyNote",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static addTherapyPatient(firstName, surname, dob, referringDoctor) {
    const user = authService.getUser();
    let params;

    if (user.internal) {
      params = {
        firstName: firstName,
        surname: surname,
        dob: dob.format("DD/MM/YYYY"),
        referringDoctor: referringDoctor,
        enteredBy: user.firstName + " " + user.surname,
        staffId: user.id
      };
    } else {
      params = {
        firstName: firstName,
        surname: surname,
        dob: dob.format("DD/MM/YYYY"),
        referringDoctor: referringDoctor,
        enteredBy: user.firstName + " " + user.surname,
        staffId: user.id,
        locationId: user.locationId
      };
    }

    return axios.post(
      "api/AddTherapyPatient",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static addTherapyScript(
    therapyId,
    diagnosticAhi,
    postTherapyAhi,
    preTherapyEss,
    postTherapyEss,
    symptomaticImprovementScore,
    averageDailyUsageHours,
    currentMask,
    currentMachinePreasureSettings,
    physicianOverride,
    note
  ) {
    const params = {
      therapyId: therapyId,
      diagnosticAhi: diagnosticAhi,
      postTherapyAhi: postTherapyAhi,
      preTherapyEss: preTherapyEss,
      postTherapyEss: postTherapyEss,
      symptomaticImprovementScore: symptomaticImprovementScore,
      AverageDailyUsageHours: averageDailyUsageHours,
      currentMask: currentMask,
      currentMachinePressureSettings: currentMachinePreasureSettings,
      physicianOverride: physicianOverride,
      note: note
    };

    return axios.post(
      "api/AddTherapyScript",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static getTherapyTypes() {
    const therapyTypes = axios.get(
      "/api/getTherapyTypes",
      this.getHeaderConfig()
    );

    return therapyTypes;
  }

  static deleteTherapyAttachment(therapyAttachmentId) {
    const params = {
      therapyAttachmentId: therapyAttachmentId
    };

    return axios.post(
      "api/DeleteTherapyAttachment",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static deleteTherapyNote(therapyLogId) {
    const params = {
      therapyLogId: therapyLogId
    };

    return axios.post(
      "api/DeleteTherapyNote",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static deleteTherapyPatient(therapyId) {
    const params = {
      therapyId: therapyId
    };

    return axios.post(
      "api/DeleteTherapyPatient",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static deleteTherapyScript(therapyScriptId) {
    const params = {
      therapyScriptId: therapyScriptId
    };

    return axios.post(
      "api/DeleteTherapyScript",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static getHeaderConfig() {
    return {
      baseURL: this.getBaseUrl(),
      headers: {
        authtoken: authService.getToken(),
        post: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    };
  }

  static getLocations() {
    return axios.get("/api/location", this.getHeaderConfig());
  }

  static getTherapyAttachments(therapyId) {
    const params = { therapyId: therapyId };

    return axios.post(
      "api/getTherapyAttachments",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static getTherapyContact(therapyId) {
    const params = { therapyId: therapyId };

    return axios.post(
      "api/getTherapyContact",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static getTherapyNotes(therapyId) {
    const params = { therapyId: therapyId };

    return axios.post(
      "api/TherapyHistory",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static getTherapyScript(therapyScriptId) {
    const params = { therapyScriptId: therapyScriptId };

    return axios.post(
      "api/TherapyScript",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static getTherapyScripts(therapyId) {
    const params = { therapyId: therapyId };

    return axios.post(
      "api/TherapyScripts",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static getSources() {
    const user = authService.getUser();

    if (user.internal === true)
      return axios.get("/api/source/0", this.getHeaderConfig());
    else
      return axios.get(
        "/api/source/" + user.locationId,
        this.getHeaderConfig()
      );
  }

  static search(filter) {
    const header = this.getHeaderConfig();

    return axios.post(
      "/api/therapy/search",
      querystring.stringify(filter),
      header
    );
  }

  static getTherapyInfo(therapyId) {
    const params = { therapyId: therapyId };

    return axios.post(
      "api/GetTherapyInfo",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static setTherapyStatus(therapyId, therapyStatusId) {
    const user = authService.getUser();

    const params = {
      therapyId: therapyId,
      therapyStatusId: therapyStatusId,
      enteredBy: user.username,
      staffId: user.id
    };

    return axios.post(
      "api/SetTherapyStatus",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static setTherapyVerified(therapyId) {
    const user = authService.getUser();

    const params = {
      therapyId: therapyId,
      enteredBy: user.username,
      staffId: user.id
    };

    return axios.post(
      "api/SetTherapyVerified",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static updateNextReviewDate(therapyId, nextReviewDate) {
    const params = {
      therapyId: therapyId,
      nextreviewDate: nextReviewDate
    };

    return axios.post(
      "api/UpdateNextReviewDate",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static uploadReferral(firstName, surname, dateOfBirth, filename, fileImage) {
    const data = new FormData();

    data.append("firstName", firstName);
    data.append("surname", surname);
    data.append("dateOfBirth", dateOfBirth.format("DD/MM/YYYY"));
    data.append(filename, fileImage);

    const config = {};

    Object.assign(config, this.getHeaderConfig());

    return axios.post("api/uploadReferral", data, config);
  }

  static uploadStudies(studies) {
    return new Promise((resolve, reject) => {
      try {
        const user = authService.getUser();
        const data = new FormData();
        const files = studies.getValid();

        data.append("staffId", user.id);

        for (let i = 0; i < files.length; i++) {
          data.append(files[i].name, files[i].image);
        }

        const config = {};

        Object.assign(config, this.getHeaderConfig());

        axios
          .post("api/uploadStudy", data, config)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  static uploadStudy(filename, fileImage) {
    return new Promise((resolve, reject) => {
      const user = authService.getUser();
      const data = new FormData();

      data.append("staffId", user.id);
      data.append("fileName", filename);
      data.append(filename, fileImage);

      const config = {};

      Object.assign(config, this.getHeaderConfig());

      axios
        .post("api/uploadStudy", data, config)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static uploadTherapyAttachment(therapyId, filename, description, fileImage) {
    return new Promise((resolve, reject) => {
      const user = authService.getUser();
      const data = new FormData();

      data.append("therapyId", therapyId);
      data.append("fileName", filename);
      data.append("description", description);
      data.append("staffId", user.id);
      data.append(filename, fileImage);

      const config = {};

      Object.assign(config, this.getHeaderConfig());

      axios
        .post("api/uploadTherapyAttachment", data, config)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static updateTherapy(
    therapyId,
    firstName,
    surname,
    dob,
    referringDoctor,
    therapyDetails,
    comment,
    therapyStatusId,
    verified,
    locationId,
    sourceId,
    therapyTypeId,
    followUpDate,
    nextReviewDate,
    endOfTrialDate
  ) {
    const user = authService.getUser();

    const params = {
      therapyId: therapyId,
      firstName: firstName,
      surname: surname,
      dob: dob,
      referringDoctor: referringDoctor,
      therapyDetails: therapyDetails,
      comment: comment,
      therapyStatusId: therapyStatusId,
      therapyTypeId: therapyTypeId,
      verified: verified,
      locationId: locationId,
      sourceId: sourceId,
      followUpDate: followUpDate,
      nextReviewDate: nextReviewDate,
      endOfTrialDate: endOfTrialDate,
      enteredBy: user.username,
      staffId: user.id
    };

    return axios.post(
      "api/updateTherapy",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static updateTherapyStatus(therapyId, therapyStatusId, comment) {
    const user = authService.getUser();

    const params = {
      therapyId: therapyId,
      therapyStatusId: therapyStatusId,
      comment: comment,
      enteredBy: user.username,
      staffId: user.id,
      userId: user.id
    };

    return axios.post(
      "api/updateTherapyStatus",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static updateTherapyComment(therapyId, comment) {
    const params = {
      therapyId: therapyId,
      comment: comment
    };

    return axios.post(
      "api/updateTherapyComment",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static updateTherapyDetail(therapyId, detail) {
    const params = {
      therapyId: therapyId,
      detail: detail
    };

    return axios.post(
      "api/updateTherapyDetail",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static updateTherapyNote(therapyLogId, enteredBy, note, staffId) {
    const user = authService.getUser();

    const params = {
      therapyLogId: therapyLogId,
      enteredBy: enteredBy,
      note: note,
      staffId: user.id
    };

    return axios.post(
      "api/updateTherapyNote",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static updateTherapyNoteText(therapyLogId, note) {
    const user = authService.getUser();

    const params = {
      therapyLogId: therapyLogId,
      note: note,
      staffId: user.id
    };

    return axios.post(
      "api/updateTherapyNoteText",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static updateTherapyContact(
    therapyId,
    title,
    homeNumber,
    mobileNumber,
    otherNumber,
    streetNumber,
    streetName,
    streetType,
    suburb,
    postcode,
    email
  ) {
    const params = {
      therapyId: therapyId,
      title: title,
      homeNumber: homeNumber,
      mobileNumber: mobileNumber,
      otherNumber: otherNumber,
      streetNumber: streetNumber,
      streetName: streetName,
      streetType: streetType,
      suburb: suburb,
      postcode: postcode,
      email: email
    };

    axios.post(
      "api/updateTherapyContact",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static updateTherapyScript(therapyScriptId, note) {
    const params = {
      therapyScriptId: therapyScriptId,
      note: note
    };

    axios.post(
      "api/updateTherapyScript",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static setTherapyScriptOverride(therapyScriptId) {
    const params = {
      therapyScriptId: therapyScriptId
    };

    return axios.post(
      "api/setTherapyScriptOverride",
      querystring.stringify(params),
      this.getHeaderConfig()
    );
  }

  static init(cb) {
    axios
      .all([
        therapy.getLocations(),
        therapy.getSources(),
        therapy.getTherapyTypes()
      ])
      .then(
        axios.spread(function(locationRes, sourceRes, therapyTypeRes) {
          if (typeof cb === "function")
            cb(locationRes.data, sourceRes.data, therapyTypeRes.data);
        })
      );
  }
}

export default therapy;
