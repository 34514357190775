const config = {
    controlFormat: 'YYYY-MM-DD',
    dateFormat: 'D/M/YYYY',
    dateTimeFormat: 'D/M/YYYY ',
    serverUrl: process.env.REACT_APP_API_URL,
    documentUrl: process.env.REACT_APP_DOC_URL,
    versionNo: '1.0.1.010',
    isDev: true,
    isTest: false
};

export default config;
