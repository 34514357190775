//import React                            from 'react';
import Moment from "moment";

import Utility from "./utility";
import config from "./config";

var dateUtility = {
  //_controlFormat: "YYYY-MM-DD",

  validateEnteredDate(dateString) {
    //const controlFormat = 'YYYY-MM-DD';
    const displayFormat = "DD/MM/YYYY";

    if (Utility.detectIE()) {
      config.controlFormat = displayFormat;
    }

    if (Moment(dateString, config.controlFormat).isValid()) {
      let currentDate = Moment(dateString, config.controlFormat);

      if (currentDate.year() > 2100) {
        currentDate.year(2100);
      }

      if (currentDate.year() < 1900) {
        currentDate.year(1900);
      }

      return currentDate.format(config.controlFormat);
    }

    return null;
  },

  validateDateOfBirth(dateString) {
    //const controlFormat = 'YYYY-MM-DD';
    const displayFormat = "DD/MM/YYYY";

    if (Utility.detectIE()) {
      config.controlFormat = displayFormat;
    }

    if (Moment(dateString, config.controlFormat).isValid()) {
      let currentDate = Moment(dateString, config.controlFormat);

      if (currentDate.year() < 1900) {
        currentDate.year(1900);
      }

      //let currentYear = Moment().year();

      if (currentDate > Moment()) {
        currentDate = Moment();
      }

      return currentDate.format(config.controlFormat);
    }

    return null;
  }
};

export default dateUtility;
