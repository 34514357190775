import React                                from 'react';
import CreateReactClass                     from 'create-react-class';
import SAlert                               from 'sweetalert2';

//import NumericUtility                       from '../../modules/numericUtility';
import Reports                              from '../../services/report-service';
import Therapy                              from '../../services/therapy-service';
import ScriptValidationService              from '../../services/script-validation-service';

import PrinterLogo                          from './images/printer.png';

export default CreateReactClass({

    getInitialState: function() {

        return {
            therapyScriptId: this.props.value
        };
    },

    onPrintClick: function() {

        let therapyScriptId = this.state.therapyScriptId;

        Therapy.getTherapyScript(therapyScriptId).then( function(response){

            response.data[0].isPrint = true;

            var result = ScriptValidationService.validateScript(response.data[0]);

            if (result.valid === true) {

                var ele = document.getElementById('downloadFrame');
                
                if (ele) {
                    document.body.removeChild(ele);
                }
        
                var downloadFrame = document.createElement('iframe');
                downloadFrame.id = 'downloadFrame';
                downloadFrame.src = Reports.makeTherapyScriptUrl(therapyScriptId, 'PDF');
                downloadFrame.className = 'hidden';
                document.body.appendChild(downloadFrame);
               
            } else {

                SAlert({
                    title: 'Validation', 
                    html: result.message,
                    type: 'error',
                    showCloseButton: true,
                    confirmButtonText: 'Set Phyician Override',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel Print',
                    width: 1000 }).then(function() {
                        // User selecte override so set flag and print

                        Therapy.setTherapyScriptOverride(therapyScriptId).then(function() {

                            var ele = document.getElementById('downloadFrame');
                            
                            if (ele) {
                                document.body.removeChild(ele);
                            }
                    
                            var downloadFrame = document.createElement('iframe');
                            downloadFrame.id = 'downloadFrame';
                            downloadFrame.src = Reports.makeTherapyScriptUrl(therapyScriptId, 'PDF');
                            downloadFrame.className = 'hidden';
                            document.body.appendChild(downloadFrame);
                        })

                        return;
                    });
            }
        });
    },

    render: function() {

        return (<img src={PrinterLogo} title="Print script" alt="delete" onClick={ this.onPrintClick } height="24" width="24" />);
    }
});
