
var stringHelpers = {

    isEmpty: function (str) {
        return (!str || 0 === str.trim().length);
    }

}

export default stringHelpers;
