import React                            from 'react';
import CreateReactClass                 from 'create-react-class';
import ReactDataGrid                    from 'react-data-grid';

//import Therapy                  from '../modules/therapy';

// -------------------------------------------------------------------------------------------------
//  CellRenderer
// -------------------------------------------------------------------------------------------------
//var cellRenderer = React.createClass({    
var cellRenderer = CreateReactClass({

    onClick: function(cell) {

        if (this.props.column.key === 'attachmentName') {

             this.props.column.getPdfUrl(this.props.rowData.attachmentID);
        }
    },

    onKeyDown: function(e) {

        if (this.props.column.key === 'attachmentName') {

            if (e.keyCode === 13)
             this.props.column.getPdfUrl(this.props.rowData.attachmentID);
        }
    },

    render() {
        return <div onClick={this.onClick} onKeyDown={this.onKeyDown} ><ReactDataGrid.Cell id="theCell" ref={(c) => {this.theCell = c;}} {...this.props}/></div>
    }
});

// -------------------------------------------------------------------------------------------------
//  RowRenderer
// -------------------------------------------------------------------------------------------------
//export default React.createClass({
var TherapyAttachmentRowRenderer = CreateReactClass({

    setScrollLeft: function(scrollBy) {
        //if you want freeze columns to work, you need to make sure you implement this as a pass through
        this.theRow.setScrollLeft(scrollBy);
    },

    getRowStyle: function() {
        return {
            color: this.getRowBackground()
        }
    },

    getRowBackground: function() {
        return this.props.row.partnerAdded ?  'blue' : 'black'
    },

    render: function() {
        return (<div style={this.getRowStyle()}><ReactDataGrid.Row id="theRow" ref={(c) => {this.theRow = c;}} {...this.props} cellRenderer={cellRenderer} /></div>)
    }
});

export default TherapyAttachmentRowRenderer