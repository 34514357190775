import Auth                             from './auth-service';

var _list = [];

var referrals = {

    getNextId () {

        var id = 0;

        for (var i = 0; i < _list.length; i++) {
            if (_list[i].id > id)
                id = _list[i].id;
        }

        return id + 1;
    },

    addFile ( file ) {

        return new Promise((resolve, reject) => {
            
            try {

                let isValid = true;
                let message = '';
                let user = Auth.getUser();
                
                if ( file.name.toLowerCase().endsWith(".pdf") === false ) {
                    isValid = false;
                    message = 'This is not a PDF document.'
                }
        
                if ( file.size > user.fileDownloadMaxSize * 1024 * 1000 ) {
                    isValid = false;
                    message = 'File is larger than allowed. Max Size: ' + user.fileDownloadMaxSize + ' MB';
                }
        
                var nextId = this.getNextId();
        
                _list.push({ 
                    id: nextId,
                    name: file.name,
                    size: file.size,
                    firstName: null,
                    surname: null,
                    dateOfBirth: null,
                    message: message,
                    image: file,
                    isValid: isValid,
                    sent: false
                });
                    
                resolve( true );
            }
            catch(ex) {

                reject( ex.message );
            }
        })            
    },

    getValid () {

        var valid = [];

        for (var i = 0; i < _list.length; i++) {
            if (_list[i].isValid)
                valid.push(_list[i]);
        }

        return valid;
    },

    hasValid () {

        for (var i = 0; i < _list.length; i++) {
            if (_list[i].isValid) {
                return true;
            }
        }

        return false;
    },

    list () {

        return _list;
    },

    remove () {

        for (var i = _list.length - 1; i >= 0; i--) {

            if (_list[i].isValid && _list[i].sent) {

                _list.splice(i, 1);
            }
        }
    },

    removeId ( id ) {
        
        for ( var i = _list.length - 1; i >= 0; i-- ) {
            
            if ( _list[i].id === id ) {

                _list.splice(i, 1);
            }
        }
    },
            
    reset () {

        _list = [];
    },

    isValid ( index ) {

        if (!_list[index].firstName || _list[index].surname.firstName ===0)
            return false;

        if (!_list[index].surname || _list[index].surname.length ===0)
            return false;

        if (!_list[index].dateOfBirth)
            return false;

        return true;
    }
};

export default referrals;
