import React                            from 'react';
import Modal                            from 'react-modal';
import CreateReactClass                 from 'create-react-class';

import TherapyScriptAdd                 from './therapy-script-add';
import TherapyScriptTable               from './therapy-script-table';

import Therapy                          from '../../services/therapy-service';

import logo                             from './images/script.png';

/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
    content : {
        top                 : '50%',
        left                : '50%',
        width               : '1200px',
        height              : '560px',
        marginleft          : '10',
        marginRight         : '10',
        transform           : 'translate(-50%, -50%)',
        background          : 'white',
        borderColor         : 'black'
    }
};

export default CreateReactClass({

    getInitialState: function() {

        return {
            modalIsOpen: false,
            info: {}
        };
    },

    loadData: function() {

       Therapy.getTherapyScripts(this.props.id).then(function(response) {
            this._dataTable.refreshTable(response.data)
        }.bind(this));
    },

    openModal: function() {

        Therapy.getTherapyInfo( this.props.id ).then( function(info) { 
            
            this.setState({

                info: info.data[0],
                modalIsOpen: true
            });
        }.bind(this))
        // this.setState({
        // });
    },

    componentDidMount () {

        if (this.props.id === undefined)
            return;
    },

    afterOpenModal: function() {

        this.loadData();
    },

    onAddClick: function() {

        Therapy.getTherapyScripts(this.props.id).then(function(response) {
            this._dataTable.refreshTable(response.data)
        }.bind(this));
    },

    onCloseModal: function() {

      this.setState({
            modalIsOpen: false
        });
    },

    render: function() {

        let title;

        if ( this.state.info !== undefined )
            title = <h3>Therapy Scripts: { this.state.info.surname + ', ' + this.state.info.firstName }</h3>;
        else
            title = <h3>Therapy Scripts</h3>;

        return (
            <div>

                <img src={this.props.theImage} title="Display Scripts" alt="Display Scripts" onClick={this.openModal} height="36" width="36" />

                <Modal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal} style={customStyles} >

                    <div >

                        <div className="panel panel-default">
                            <div className="panel-body title-panel">
                                <div className="header">
                                    <img src={logo} alt="Display attachments" onClick={this.openModal} height="24" width="24" />
                                    { title }
                                </div>
                            </div>
                        </div>

                        <div style={{height: 100}}>

                            <TherapyScriptTable ref={(dataTable) => {this._dataTable = dataTable}} therapyId={this.props.id} refreshData={this.loadData} tabIndex="50" />

                            <br />

                            <div className="form-inline btn-toolbar" style={{height: 40}} >
                                <TherapyScriptAdd id={this.props.id} className="btn btn-primary" addClick={this.onAddClick} closeClick={this.onCloseModal} />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
});
