import React                            from 'react'
import Modal                            from 'react-modal';
import CreateReactClass                 from 'create-react-class';

import logo                             from '../headers/images/sleep-wa-logo.png';

const customStyles = {
  content : {
    top                 : '50%',
    left                : '50%',
    width               : '430px',
    height              : '520px',
    marginleft          : 'auto',
    marginRight         : 'auto',
    transform           : 'translate(-50%, -50%)',
    background          : 'white',
    borderColor         : 'black'
  }
};

//export default React.createClass({
var ShowSleepContact = CreateReactClass({

    getInitialState() {
        return {
            modalIsOpen: false
        };
    },

    onOpenModal: function() {
        this.setState({
            modalIsOpen: true
        });
    },

    onCloseModal: function() {
        this.setState({
            modalIsOpen: false
        });
    },

    render() {
            
        return (
            <div className="form-group btn-toolbar">
                
                {/*<a href="" onClick={this.onOpenModal} style={{ color: 'white'}} >Contact Sleep WA</a>*/}

                <Modal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} style={customStyles} >

                    <div>
                        <div className="panel panel-default">
                            <div className="panel-body title-panel">
                                
                                <img src={logo} title="General Enqueries" alt="" height="50px" onClick={this.openModal} />
                                
                                <div className="header">
                                    <h3>Contact Sleep WA</h3>
                                </div>
                            </div>
                        </div>
                        
                        <br /><p className="info-header">General and Clinical Enquiries – Dion Hulshoff</p >
                        Mobile: 0423 278 972
                        <br />Email: <a href="mailto: dion.hulshoff@sleepwa.com.au">dion.hulshoff@sleepwa.com.au</a>
                        <br />
                        <br /><p className="info-header">Physician Enquiries – Dr. Jack Philpott</p>
                        Email: <a href="mailto: jack.philpott@sleepwa.com.au">jack.philpott@sleepwa.com.au</a>
                        <br />
                        <br /><p className="info-header">General enquiries unrelated to the Masterfile software</p>
                        Phone: 1300 570 700
                        <br />Email: <a href="mailto: secretary@sleepwa.com.au">secretary@sleepwa.com.au</a>
                    </div>
                    <br />
                    <br />
                    <div className="btn-toolbar pull-right" data-toggle="buttons">
                        <button type="button" className="btn btn-primary ss-button" onClick={this.onCloseModal}>Close</button>
                    </div>
                </Modal>
            </div>
        );
    }
});

export default ShowSleepContact;