import React from "react";
import ReactDataGrid from "react-data-grid";
import Modal from "react-modal";

import RowRenderer from "./therapy-note-table-row-renderer";

import WrapTextFormatter from "../../controls/wrap-text-formatter";

import authService from "../../services/auth-service";
import Therapy from "../../services/therapy-service";

import Logo from "./images/notes.png";

// -------------------------------------------------------------------------------------------------
//  Style for Note Modal
// -------------------------------------------------------------------------------------------------
const noteStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "800px",
    height: "417px",
    marginleft: "10",
    marginRight: "10",
    transform: "translate(-50%, -50%)",
    background: "white",
    borderColor: "black"
  }
};

// -------------------------------------------------------------------------------------------------
//  Grid
// -------------------------------------------------------------------------------------------------
class TherapyNoteTable extends React.Component {
  constructor(props) {
    super(props);

    let user = authService.getUser();

    this.state = {
      allowEdit: false,
      rows: [],
      user: user
    };
  }

  openNoteDlg = rowIdx => {
    let row = this.state.rows[rowIdx];
    let enteredBy = this.state.user.firstName + " " + this.state.user.surname;
    let allowEdit = enteredBy === row.enteredBy;

    this.setState({
      therapyLogId: row.therapyLogId,
      note: row.note,
      modalTitle: "Note",
      allowEdit: allowEdit,
      modalCloseClick: this.onCloseNoteClick,
      modalSaveClick: this.onSaveNoteClick,
      modalIsOpen: true
    });
  };

  onCloseNoteClick = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  onSaveNoteClick = () => {
    var id = this.state.therapyLogId;
    var note = this.state.note;

    Therapy.updateTherapyNoteText(id, note);

    var rows = this.state.rows;

    for (var i = 0; i < rows.length; i++) {
      if (rows[i].therapyLogId === id) {
        rows[i].note = note;

        this.refreshTable(rows);
        break;
      }
    }

    this.setState({
      modalIsOpen: false
    });
  };

  handleCommentChanged = event => {
    this.setState({
      note: event.target.value
    });
  };

  buildColumns = () => {
    var columns = [
      {
        key: "entryTime",
        name: "Entered On",
        width: 150,
        editable: false
      },
      {
        key: "enteredBy",
        name: "Entered By",
        width: 150,
        editable: false
      },
      {
        key: "note",
        name: "Note",
        openModal: this.openNoteDlg,
        formatter: <WrapTextFormatter height={90} />
      }
    ];

    return columns;
  };

  rowGetter = rowIdx => {
    return this.state.rows[rowIdx];
  };

  updateRow = update => {
    var resp = Therapy.updateTherapyNote(
      update.therapyLogId,
      update.enteredBy,
      update.note,
      update.staffId,
      update.followUp,
      update.followUpDate
    ).then(e => {
      if (resp.data === "") return true;

      return false;
    });
  };

  handleRowUpdated = e => {
    //merge updated row with current row and rerender by setting state
    var rows = this.state.rows;

    switch (e.cellKey) {
      case "note":
        if (e.updated.note === rows[e.rowIdx].note) return;
        break;

      default:
        return;
    }

    Object.assign(rows[e.rowIdx], e.updated);

    if (this.updateRow(rows[e.rowIdx]) === true) {
      this.setState({
        rows: rows
      });

      return;
    }
  };

  onCellSelected = cell => {
    if (this.grid !== null) this.grid.openCellEditor(cell.rowIdx, cell.idx);
  };

  refreshTable = newRows => {
    this.setState({
      rows: []
    });

    this.setState({
      rows: newRows
    });
  };

  render = () => {
    let columns = this.buildColumns();
    let buttons;
    let message;

    if (this.state.allowEdit) {
      message = null;
      buttons = (
        <div>
          <div>
            <textarea
              id="commentText"
              value={this.state.note}
              cols={102}
              rows={12}
              onChange={this.handleCommentChanged}
              maxlength="255"
              style={{ resize: "none" }}
              tab="51"
              autoFocus
            />
          </div>
          <div className="btn-toolbar pull-right" data-toggle="buttons">
            <button
              type="button"
              className="btn btn-primary btn-sm ss-button"
              onClick={this.state.modalSaveClick}
              tab="52"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm ss-button"
              onClick={this.state.modalCloseClick}
              tab="53"
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      message = (
        <h3 style={{ color: "red" }}>
          Unable to edit this note as it is not yours.
        </h3>
      );
      buttons = (
        <div className="btn-toolbar pull-right" data-toggle="buttons">
          <textarea
            id="commentText"
            value={this.state.note}
            cols={105}
            rows={12}
            maxlength="255"
            readonly
            style={{ resize: "none" }}
            tabIndex="51"
          />

          <br />
          <br />
          <div>
            <button
              type="button"
              className="btn btn-primary btn-sm ss-button"
              onClick={this.state.modalCloseClick}
              tabIndex="52"
            >
              Close
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div>
          <ReactDataGrid
            id="grid"
            ref={c => {
              this.grid = c;
            }}
            enableCellSelect={true}
            columns={columns}
            rowsCount={this.state.rows.length}
            headerHeight={50}
            rowHeight={100}
            rowRenderer={RowRenderer}
            minHeight="600px"
            maxWidth="300px"
            rowGetter={this.rowGetter}
            onRowUpdated={this.handleRowUpdated}
            onCellSelected={this.onCellSelected}
            tabIndex="55"
          />
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={noteStyles}
          >
            <div>
              <div className="panel panel-default">
                <div className="panel-body title-panel">
                  <div className="header">
                    <img
                      src={Logo}
                      title="Additional Info"
                      alt="Additional Info"
                      onClick={this.openModal}
                      height="24"
                      width="24"
                    />
                    <h3>{this.state.modalTitle}</h3>
                  </div>
                </div>
              </div>

              <div>{message}</div>

              <div>{buttons}</div>
            </div>
          </Modal>
        </div>
      </div>
    );
  };
}

export default TherapyNoteTable;
