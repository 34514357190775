import React                            from 'react';
import CreateReactClass                 from 'create-react-class';

import logo                             from './images/calendar6.png';

export default CreateReactClass({

    getInitialState () {

      return {
          id: this.props.value
      };
    },

    render () {

        return (
            <img src={logo} title="Update Review Date 6 Months" alt="delete" height="24" width="24" />
        )
    }
})
