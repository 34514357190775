import React                            from 'react';
import CreateReactClass                 from 'create-react-class';
import SAlert                           from 'sweetalert2';

import Therapy                          from '../../services/therapy-service';

import DeleteLogo                       from './images/delete.png';

export default CreateReactClass({

    getInitialState: function() {

      return {
          therapyScriptId: this.props.value
      };
    },

    onDeleteClick: function (e) {
        e.preventDefault();

        //var therapyScriptId = this.state.therapyScriptId;
        var therapyScriptId = this.props.value;
        var onDeleteRow = this.props.onDeleteRow;

        SAlert({
            title: "Confirm",
            text: "Delete selected script?",
            showCancelButton: true,
            confirmButtonText: "Delete",
            closeOnConfirm: false,
            closeOnCancel: false
        }).then(function() {

            Therapy.deleteTherapyScript(therapyScriptId).then((resp) => {

                if (resp.data.success) {

                    onDeleteRow(therapyScriptId);
                    //SA({title: "", text: resp.data.message});
                    return true;
                } else {

                    SAlert({title: "", text: resp.data.message});
                    return false;
                }
            });
        });
    },

    render: function () {

        return (
            <img src={DeleteLogo} title="Delete Script" alt="delete" onClick={this.onDeleteClick} height="32" width="32" />
        )
    }
})
