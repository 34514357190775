import React                            from 'react';
import CreateReactClass                 from 'create-react-class';
//import SAlert                           from 'sweetalert2';

//import Therapy                          from '../../modules/therapy';

import Logo                             from './images/delete.png';

export default CreateReactClass({

    getInitialState: function() {

      return {
          id: this.props.value,
          patient: ''
      };
    },

    onDeleteClick: function (e) {
        e.preventDefault();

        let handleDeleteRow = this.props.onDeleteRow;

        handleDeleteRow(this.props.rowIdx);
    },

    // onDeleteClick: function (e) {
    //     e.preventDefault();

    //     let therapyId       = this.state.id;
    //     let rowIdx          = this.props.rowIdx;
    //     let handleDeleteRow = this.props.handleDeleteRow;
       
    //     Therapy.getTherapyInfo(therapyId).then(function(info) {

    //         SAlert({
    //             title: "Confirm Delete",
    //             text: "Delete selected patient? " + info.data[0].surname + ', ' + info.data[0].firstName,
    //             type: "warning",
    //             showCancelButton: true,
    //             cancelButtonText: "Cancel",
    //             confirmButtonText: "Yes Delete",
    //             closeOnConfirm: false,
    //             closeOnCancel: false
    //         }).then(function(isConfirm) {
    
    //             if (isConfirm) {
    
    //                 Therapy.deleteTherapyPatient(therapyId).then((resp) => {
    
    //                     if (resp.data.success) {
    
    //                         handleDeleteRow(therapyId);
    //                         //SAlert({title: "", text: resp.data.message});
    //                         return true;
    //                     } else {
    
    //                         //SAlert({title: "Delete record", text: resp.data.message});
    //                         return false;
    //                     }
    //                 });
    
    //             } else {
    
    //                 //SA({title: "", text: "Patient delete cancelled."});
    //                 return false;
    //             }
    //         })
    //         });
    // },

    render: function () {

        return (
            <img src={Logo} title="Delete record" alt="delete" onClick={this.onDeleteClick} height="24" width="24" />
        )
    }
})
