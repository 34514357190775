import React                            from 'react';
import CreateReactClass                 from 'create-react-class';
import ReactDataGrid                    from 'react-data-grid';

//import GridHeader                       from '../headers/GridHeader';

import StudyUploadDelete                from './study-upload-delete';
import StudyUploadTableRowRenderer      from './study-upload-table-row-renderer';

//import studies                          from '../../modules/studies';

//import 'react-data-grid/dist/react-data-grid.css'

export default CreateReactClass({

    getInitialState : function(){

        // console.log('AttachmentTable::getInitialState');

        return {
            rows: []
        };
    },

    componentDidMount: function() {

        // console.log('AttachmentTable::componentDidMount');

        this.refreshTable( [] );
    },

    refreshTable: function( newRows ) {

        this.setState({
            rows: []
        });

        this.setState({
            rows: newRows
        });
    },

    onDeleteRow: function(id) {

        var data = this.state.rows;
        var newData = [];
        var i = -1;
        var j = -1;

        newData.length = data.length;

        if (newData.length === 0) {

            return;
        }

        while (i < newData.length - 1) {

            i++;

            console.log('Loop i=' + i + ' j=' + j);
            console.log('Loop id=' + data[i].id + ' therapyId=' + id);

            if (data[i].id === id) {
                i++;
            }

            if (i < data.length) {

                j++;

                console.log('Loop i=' + i + ' j=' + j);
                console.log('data[' + i + ']=' + data[i]);
                console.log('newData[' + j + ']=' + newData[j]);

                newData[j] = data[i];
            }
        }

        newData.length = j + 1;

        this.setState({
            rows:newData
        });
    },

    buildColumns: function() {

        // console.log('AttachmentTable::buildColumns');

        var columns = [
            {
                key: 'id',
                name: '',
                editable: false,
                width: 48,
                editor: <StudyUploadDelete onDeleteRow={this.onDeleteRow} />,
                formatter: <StudyUploadDelete  onDeleteRow={this.onDeleteRow} />
            },
            {
                key: 'name',
                name: 'Name',
                resizable : true,
                width: 450,
                editable: false
            },
            {
                key: 'size',
                name: 'File Size',
                resizable : true,
                width: 280,
                editable: false
            },
            {
                key: 'message',
                name: '',
                resizable : true,
                width: 400,
                editable: false
            }
        ]

        // console.log('AttachmentTable::buildColumns - complete');

        return columns;
    },

    rowGetter : function(rowIdx){

        // console.log('AttachmentTable::rowGetter');

        return this.state.rows[rowIdx]
    },

    handleRowUpdated : function(e){

        //merge updated row with current row and rerender by setting state
        var rows = this.state.rows;
        Object.assign(rows[e.rowIdx], e.updated);

        this.setState({
            rows:rows
        });
    },

    render: function(){

        // console.log('AttachmentTable::render');

        let columns = this.buildColumns();

        return (
            <div>
                <ReactDataGrid
                    columns={columns}
                    rowGetter={this.rowGetter}
                    rowsCount={this.state.rows.length}
                    rowRenderer={StudyUploadTableRowRenderer}
                    minHeight={340}
                    maxHeight={200}
                    width="720"
                    tabIndex="51" />
            </div>
        )
    }
});
