import React from "react";
import CreateReactClass from "create-react-class";
import PropTypes from "prop-types";
import Moment from "moment";

import config from "../../../modules/config";
import DateUtility from "../../../modules/date-utility";

import ReferralUpload from "../../referrals/referral-upload";
import StudyUpload from "../../studies/study-upload";

import "./css/search-toolbar.css";

export default CreateReactClass({
  propTypes: {
    locations: PropTypes.array.isRequired,
    sources: PropTypes.array.isRequired
  },

  getInitialState() {
    this.searchCriteria = {
      selectedStatus: "1",
      selectedSource: { value: 0, label: "*" },
      selectedLocation: { value: 0, label: "*" },
      startReportDate: null,
      endReportDate: null,
      dateOfBirth: null,
      showDeleted: "0",
      updateFilter: true
    };

    return {
      selectedStatus: "1"
    };
  },

  onClearFilterClick() {
    this.EndDateAddedControl.value = "";
    this.searchCriteria.endReportDate = "";

    this.StartDateAddedControl.value = "";
    this.searchCriteria.startReportDate = "";

    this.LocationControl.selectedIndex = 0;
    this.searchCriteria.selectedLocation = { value: "0", label: "*" };

    if (this.SourceControl === undefined) {
      this.searchCriteria.selectedSource = { value: "0", label: "*" };
    } else {
      this.SourceControl.selectedIndex = 0;
      this.searchCriteria.selectedSource = { value: "0", label: "*" };
    }

    this.FirstNameControl.value = "";
    this.searchCriteria.FirstName = "";

    this.SurnameControl.value = "";
    this.searchCriteria.Surname = "";

    this.DateOfBirthControl.value = "";
    this.searchCriteria.dateOfBirth = "";

    this.DeleteControl.selectedIndex = 0;
    this.searchCriteria.showDeleted = "0";

    this.updateFilter();
  },

  refreshList() {
    this.updateFilter();
  },

  onFirstNameChanged(option) {
    this.searchCriteria.FirstName = option;
    this.updateFilter();
  },

  onLocationChanged(event) {
    this.searchCriteria.selectedLocation = event.target.value;
    this.updateFilter();
  },

  onEndDateChanged(option) {
    let theDate = this.searchCriteria.endReportDate;

    // Clear search date
    this.searchCriteria.endReportDate = null;

    // Validate new date string
    let newDate = DateUtility.validateEnteredDate(
      this.EndDateAddedControl.value
    );

    if (theDate !== newDate) {
      if (this.StartDateAddedControl.value === "") {
        if (newDate !== "") {
          this.StartDateAddedControl.value = Moment(
            this.EndDateAddedControl.value,
            config.controlFormat
          ).format(config.controlFormat);
        }
      }

      if (
        Moment(this.StartDateAddedControl.value, config.controlFormat) >
        Moment(this.EndDateAddedControl.value, config.controlFormat)
      ) {
        this.StartDateAddedControl.value = this.EndDateAddedControl.value;
      }

      this.EndDateAddedControl.value = newDate;
      this.searchCriteria.endReportDate = Moment(newDate, config.controlFormat);

      this.updateFilter();
    }
  },

  onDateOfBirthChanged(option) {
    let theDate = this.searchCriteria.dateOfBirth;

    // Clear search date
    this.searchCriteria.endReportDate = null;

    // Validate new date string
    let newDate = DateUtility.validateDateOfBirth(
      this.DateOfBirthControl.value
    );

    if (theDate !== newDate) {
      this.DateOfBirthControl.value = newDate;
      this.searchCriteria.dateOfBirth = Moment(newDate, config.controlFormat);

      this.updateFilter();
    }
  },

  onStartDateChanged(target) {
    let theDate = this.searchCriteria.startReportDate;

    // Clear search date
    this.searchCriteria.startReportDate = null;

    // Validate new date string
    let newDate = DateUtility.validateEnteredDate(
      this.StartDateAddedControl.value
    );

    if (theDate !== newDate) {
      this.StartDateAddedControl.value = newDate;
      // this.searchCriteria.startReportDate = Moment(
      //   newDate,
      //   'DD/MM/YYYY'config.controlFormat
      // );
      this.searchCriteria.startReportDate = Moment(
        newDate,
        config.controlFormat
      );

      if (
        this.StartDateAddedControl.value !== "" &&
        this.EndDateAddedControl.value === ""
      ) {
        this.EndDateAddedControl.value = Moment().format(config.controlFormat);
        this.searchCriteria.endReportDate = Moment();
      }

      if (
        Moment(this.EndDateAddedControl.value, config.controlFormat) <
        Moment(this.StartDateAddedControl.value, config.controlFormat)
      ) {
        this.EndDateAddedControl.value = this.StartDateAddedControl.value;
      }

      this.updateFilter();
    }
  },

  onSourceChanged(event) {
    this.searchCriteria.selectedSource = event.target.value;
    this.updateFilter();
  },

  onDeletedChanged(event) {
    this.searchCriteria.showDeleted = event.target.value;
    this.updateFilter();
  },

  onStatusChanged(event) {
    var option = event.target.value;

    console.log(
      "SearchInternalToolbar::onStatusChanged( event.target.value: " +
        option +
        " )"
    );
    this.setState(
      {
        selectedStatus: option
      },
      () => {
        this.updateFilter();
      }
    );
  },

  onSurnameChanged() {
    this.searchCriteria.Surname = this.SurnameControl.value;
    this.updateFilter();
  },

  updateFilter() {
    let locationId = this.searchCriteria.selectedLocation;
    if (locationId === "*") locationId = 0;

    if (this.props.sources.length === 1) {
      this.searchCriteria.selectedSource = this.props.sources[0].value;
    }

    let sourceId = this.searchCriteria.selectedSource;
    if (sourceId === "*") sourceId = 0;

    let startReportDate =
      this.searchCriteria.startReportDate === null ||
      this.searchCriteria.startReportDate === ""
        ? null
        : this.searchCriteria.startReportDate.format("YYYY-MM-DD");
    let endReportDate =
      this.searchCriteria.endReportDate === null ||
      this.searchCriteria.endReportDate === ""
        ? null
        : this.searchCriteria.endReportDate.format("YYYY-MM-DD");
    let dateOfBirth =
      this.searchCriteria.dateOfBirth === null ||
      this.searchCriteria.dateOfBirth === ""
        ? null
        : this.searchCriteria.dateOfBirth.format("YYYY-MM-DD");

    var filter = {
      therapyStatusId: this.state.selectedStatus,
      verified: false,
      startReportDate: startReportDate,
      endReportDate: endReportDate,
      firstName: this.FirstNameControl.value,
      surname: this.SurnameControl.value,
      dateOfBirth: dateOfBirth,
      locationId: locationId,
      sourceId: sourceId,
      showDeleted: this.searchCriteria.showDeleted
    };

    this.props.updateFilter(filter);
  },

  render() {
    let { locations, sources } = this.props;
    let deleteDropDown = <div>Loading</div>;
    let locationDropDown = <div>Loading</div>;
    let sourceDropDown = <div>Loading</div>;

    if (locations.length > 0) {
      let locOptions = locations.map(l => (
        <option key={l.value} value={l.value}>
          {l.label}
        </option>
      ));

      locationDropDown = (
        <select
          className="form-control form-control-sm"
          id="LocationControl"
          onChange={this.onLocationChanged}
          ref={c => {
            this.LocationControl = c;
          }}
          tabIndex="12"
        >
          {locOptions}
        </select>
      );
    }

    if (sources.length > 0) {
      let srcOptions = sources.map(l => (
        <option key={l.value} value={l.value}>
          {l.label}
        </option>
      ));

      sourceDropDown = (
        <select
          className="form-control form-control-sm"
          id="SourceControl"
          onChange={this.onSourceChanged}
          ref={c => {
            this.SourceControl = c;
          }}
          tabIndex="13"
        >
          {srcOptions}
        </select>
      );
    }

    deleteDropDown = (
      <select
        className="form-control form-control-sm"
        id="DeleteControl"
        onChange={this.onDeletedChanged}
        ref={c => {
          this.DeleteControl = c;
        }}
        tabIndex="16"
      >
        <option key={0} value="0">
          Do Not Show
        </option>
        <option key={1} value="1">
          Include Deleted
        </option>
        <option key={2} value="2" color="red">
          Show Only Delete
        </option>
      </select>
    );

    return (
      <div>
        <div className="toolbarContainer">
          <table width="1260px">
            <tbody>
              <tr>
                <td style={{ width: "300px" }}>
                  <h5
                    style={{
                      textAlign: "center",
                      color: "purple",
                      fontWeight: "bold"
                    }}
                  >
                    Therapy Status:
                  </h5>

                  <table
                    style={{
                      width: "280px",
                      margin: "10px",
                      border: "1 solid purple"
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "110px" }}>
                          <input
                            type="radio"
                            name="inlineRadioOption1"
                            value="0"
                            checked={this.state.selectedStatus === "0"}
                            onChange={this.onStatusChanged}
                            tabIndex="1"
                          />{" "}
                          All
                        </td>
                        <td style={{ width: "170px", textAlign: "left" }}>
                          <input
                            type="radio"
                            name="inlineRadioOption2"
                            value="3"
                            checked={this.state.selectedStatus === "3"}
                            onChange={this.onStatusChanged}
                            tabIndex="5"
                          />{" "}
                          Currently Trialling
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "110px" }}>
                          <input
                            type="radio"
                            name="inlineRadioOption3"
                            value="1"
                            checked={this.state.selectedStatus === "1"}
                            onChange={this.onStatusChanged}
                            tabIndex="2"
                          />{" "}
                          New
                        </td>
                        <td style={{ width: "170px", textAlign: "left" }}>
                          <input
                            type="radio"
                            name="inlineRadioOption4"
                            value="4"
                            checked={this.state.selectedStatus === "4"}
                            onChange={this.onStatusChanged}
                            tabIndex="6"
                          />{" "}
                          Completed & on-going
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "110px" }}>
                          <input
                            type="radio"
                            name="inlineRadioOption5"
                            value="7"
                            checked={this.state.selectedStatus === "7"}
                            onChange={this.onStatusChanged}
                            tabIndex="3"
                          />{" "}
                          Verified
                        </td>
                        <td style={{ width: "130px", textAlign: "left" }}>
                          <input
                            type="radio"
                            name="inlineRadioOption6"
                            value="5"
                            checked={this.state.selectedStatus === "5"}
                            onChange={this.onStatusChanged}
                            tabIndex="7"
                          />{" "}
                          Declined
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "110px" }}>
                          <input
                            type="radio"
                            name="inlineRadioOption7"
                            value="2"
                            checked={this.state.selectedStatus === "2"}
                            onChange={this.onStatusChanged}
                            tabIndex="4"
                          />{" "}
                          Contacted
                        </td>
                        <td style={{ width: "130px", textAlign: "left" }}>
                          <input
                            type="radio"
                            name="inlineRadioOption8"
                            value="6"
                            checked={this.state.selectedStatus === "6"}
                            onChange={this.onStatusChanged}
                            tabIndex="8"
                          />{" "}
                          Terminated
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ width: "370px" }}>
                  <table style={{ width: "350px", margin: "10px" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "100px", textAlign: "right" }}>
                          <label
                            htmlFor="StartDateAddedControl"
                            style={{ marginRight: "5px" }}
                            className="control-label"
                          >
                            Date Added:
                          </label>
                        </td>
                        <td style={{ width: "250px" }}>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            id="StartDateAddedControl"
                            ref={c => {
                              this.StartDateAddedControl = c;
                            }}
                            onBlur={this.onStartDateChanged}
                            pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                            tabIndex="9"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <label
                            className="control-label"
                            style={{ marginRight: "5px" }}
                          >
                            Location:
                          </label>
                        </td>
                        <td>{locationDropDown}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <label
                            htmlFor="FirstNameControl"
                            style={{ marginRight: "5px" }}
                            className="control-label"
                          >
                            First Name:
                          </label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="FirstNameControl"
                            onChange={this.onFirstNameChanged}
                            ref={c => {
                              this.FirstNameControl = c;
                            }}
                            placeholder="First Name"
                            tabIndex="14"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <label
                            htmlFor="SurnameControl"
                            style={{ marginRight: "5px" }}
                            className="control-label"
                          >
                            Surname:
                          </label>
                        </td>
                        <td style={{ width: "110px" }}>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="SurnameControl"
                            onChange={this.onSurnameChanged}
                            ref={c => {
                              this.SurnameControl = c;
                            }}
                            placeholder="Surname"
                            tabIndex="15"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ width: "370px" }}>
                  <table style={{ width: "350px", margin: "10px" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "70px", textAlign: "right" }}>
                          <label
                            htmlFor="EndDateAddedControl"
                            className="control-label"
                            style={{ marginRight: "5px" }}
                          >
                            To:
                          </label>
                        </td>
                        <td style={{ width: "280px" }}>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            id="EndDateAddedControl"
                            ref={c => {
                              this.EndDateAddedControl = c;
                            }}
                            onBlur={this.onEndDateChanged}
                            placeholder="End of Range"
                            pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                            tabIndex="10"
                            autoFocus
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <label
                            className="control-label"
                            style={{ marginRight: "5px" }}
                          >
                            Source:
                          </label>
                        </td>
                        <td>{sourceDropDown}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <label
                            htmlFor="DateOfBirthControl"
                            style={{ marginRight: "5px" }}
                          >
                            DOB:
                          </label>
                        </td>
                        <td>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            id="DateOfBirthControl"
                            onBlur={this.onDateOfBirthChanged}
                            ref={c => {
                              this.DateOfBirthControl = c;
                            }}
                            placeholder="Date Of Birth"
                            pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                            tabIndex="16"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <label
                            className="control-label"
                            style={{ marginRight: "5px" }}
                          >
                            Deleted:
                          </label>
                        </td>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td style={{ width: "80px" }}>
                                  {deleteDropDown}
                                </td>
                                <td style={{ alignContent: "right" }}>
                                  <button
                                    className="btn btn-primary btn-sm btn-block"
                                    style={{ width: "90px" }}
                                    onClick={this.onClearFilterClick}
                                    tabIndex="17"
                                  >
                                    Clear Filter
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table style={{ width: "180px", margin: "10px" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "100%", align: "right" }}>
                          <ReferralUpload
                            text="Upload Referral"
                            tabIndex="20"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <StudyUpload text="Upload Referral" tabIndex="20" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ width: "100%", align: "right" }}>
                            <div style={{ width: "100%", align: "right" }}>
                              <button
                                className="btn btn-primary btn-block btn-sm"
                                style={{ width: "160px", margin: "3px" }}
                                onClick={this.refreshList}
                                tabIndex="22"
                              >
                                Refresh List
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
});
