import React                            from 'react';
import Modal                            from 'react-modal';
import CreateReactClass                 from 'create-react-class';
import SAlert                           from 'sweetalert2';

import StudyUploadTable                 from './study-upload-table';

import FileUpload                       from '../../controls/file-upload';

import Auth                             from '../../services/auth-service';
import Therapy                          from '../../services/therapy-service';
import Studies                          from '../../services/study-service';

import logo                             from './images/attachments.png';

/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
  content : {
    top                     : '50%',
    left                    : '50%',
    width                   : '1100px',
    height                  : '585px',
    marginleft              : 'auto',
    marginRight             : 'auto',
    transform               : 'translate(-50%, -50%)',
    background              : 'white',
    borderColor             : 'black'
  }
};

export default CreateReactClass({

    _message: '',

    getInitialState () {

        // this.empty = this.empty.bind(this);
        // this.onCloseModal = this.onCloseModal.bind(this);
        // this.onDrop = this.onDrop.bind(this);
        // this.onSendClick = this.onSendClick.bind(this);
        // this.openModal  = this.openModal.bind(this);
        // this.postUpload = this.postUpload.bind(this);
        // this.userHasStudyFolder = this.userHasStudyFolder.bind(this);
        // this.render = this.render.bind(this);

        return {
            studies: Studies.list(),
            modalIsOpen: false,
            rows: []
        };
    },

    openModal ( evt ) {
        evt.preventDefault();

        if (!this.userHasStudyFolder()) {

            SAlert({

                title: "File Upload",
                text: "Your account has not been set up for study uploads.",
                type: 'info'
            });

            return;
        }

        Studies.reset();

        this.setState({
            modalIsOpen: true
        });
    },

    onCloseModal () {
        this.setState({
            modalIsOpen: false
        });
    },

    onDrop ( files ) {

        var self = this;

        for (var i = 0; i < files.length; i++) {

            if (files[i].size === 0) {
                continue;
            }

            Studies.addFile(files[i]).then(( result ) => {

                if ( result ) {

                    self._grid.refreshTable(Studies.list())
                    // self.setState({
                    //     studies: Studies.list()
                    // });
                }
            });
        };
    },

    userHasStudyFolder () {

        var user = Auth.getUser();

        if (this.empty(user.studyFolderLocation))
            return false;

        return true;
    },

    empty ( e ) {
        switch (e) {
          case "":
          case 0:
          case "0":
          case null:
          case false:
          case typeof this === "undefined":
            return true;
          default:
            return false;
        }
    },

    onSendClick () {

        if (!this.userHasStudyFolder()) {

            SAlert({
                title: 'Study Upload', 
                html: 'Your account has not been set up for study uploads.', 
                type: 'warning'
            });
            
            return;
        }

        if (!Studies.hasValid()) {

            SAlert({
                title: "Study Upload", 
                html: 'There are <b>NO valid files</b> to send.', 
                type: 'warning'
            });

            return;
        }
        
        var self = this;

        SAlert({
            title: 'Send Studies',
            text: 'Send ' + Studies.getValid().length + (Studies.getValid().length > 1 ? ' files.' : ' file.'),
            type: 'question',
            showCancelButton: true
        }).then(function() {

            //var files = Studies.getValid();
            
            //self.postAllUploads(files).then(function (result, message) {
            self.sendUploads(Studies)
                .then(function (resp) {

                    //var message;
                    //var messageType;

                    if (resp.data.success) {

                        SAlert({
                            title:  'Study Upload',
                            html:   resp.data.message,
                            type:   "info",
                            width:  800
                        });

                        if (Studies.list.length === 0) {
                            self.onCloseModal();
                        }    
                    }
                    else {

                        SAlert({
                            title:  'Study Upload',
                            html:   '<b>Error Transfering Studies</b><br/><br/>' + resp.data.message,
                            type:   'warning',
                            width:  800
                        });
                    }
                
                    self.setState({
                        studies: Studies.list()
                    });
                }).catch(err => {
                    SAlert({
                        title: "Study Upload",
                        html: err,
                        type: "warning"
                    })
                });
        });
    },

    sendUploads(files) {

        return new Promise((resolve, reject) => {
            
            try {

                Therapy.uploadStudies(files)
                    .then(function (resp) {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    })
            }
            catch (e) {
                reject(e)
            }
        })
    },

    postAllUploads(files) {

        return new Promise((resolve, reject) => {

            try {

                let result = true;
                let message = '';
    
                for (var i = 0; i < files.length; i++) {
        
                    if (files[i].isValid) {

                        this.SendFile(files[i], result, message);
                    }
                }
                            
                resolve(result, message);
            }
            catch (e) {
                reject(e)
            }
        })
    },

    sendFile(file, result, message) {

        try {
            
            Therapy.uploadStudy(file.name, file.image)
                .then((resp) => {
                    
                    if (resp.status === 200) {

                        file.sent = true;
                        file.desc = 'ADDED: ' + resp.data.message;
                        result = true;
                        message += 'ADDED: ' + resp.data.message + '<br />';

                    } else {

                        file.sent = false;
                        file.desc = resp.data.message;
                        result = false;
                        message += 'FAILED: ' + resp.data.message + '<br />';
                    }
                })
                .catch(err => {

                    SAlert({
                        title: "Study Upload",
                        text: err,
                        type: "warning"        
                    });
                });
        } catch (err) {
            
            SAlert({
                title: "Study Upload",
                text: err,
                type: "warning"
            });
        }
    },

    postUpload ( file ) {

        let result = false;
        let message = ''

        return new Promise((resolve, reject) => {
            
            Therapy.uploadStudy(file.name, file.image).then((resp) => {
                
                if (resp.status === 200) {

                    file.sent = true;
                    file.desc = 'ADDED: ' + resp.data.message;
                    result = true;
                    message = 'ADDED: ' + resp.data.message;

                } else {

                    file.sent = false;
                    file.desc = resp.data.message;
                    result = false;
                    message = 'FAILED: ' + resp.data.message;
                }

                Studies.remove(result, message);

                resolve(message);
            });
        })
    },

    render () {

        let user = Auth.getUser();

        let uploadControl;
        let sendButton;

        //let data = this.studies;

        if ( user.studyFolderLocation.length > 0 ) {

            uploadControl = <FileUpload Id={this.props.id} ref={(c) => {this.fileUpload = c;}} onDrop={ this.onDrop } />;
            sendButton = <button id="SendButton" ref={(c) => {this.SendButton = c;}} className="btn btn-primary btn-sm ss-button" onClick={this.onSendClick} tabIndex="53" >Send</button>;

        } else {

            uploadControl = <p>User does not have a Study Folder Location set. Can not transfer files</p>;
            sendButton = '';

        }

        return (
            <div style={{ alignContent: 'right' }}>
                {/* <button className="btn btn-primary btn-block" style={{ width: '90px'}} onClick={ this.onClearFilterClick } tabIndex="10" >Clear Filter</button> */}
                <button className="btn btn-primary btn-block btn-sm" style={{ width: '160px', margin: '3px'}} onClick={this.openModal} tabIndex="21" >Upload Study</button>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    style={customStyles} >
                    <div >

                        <div className="panel panel-default">
                            <div className="panel-body title-panel">
                                <div className="header">
                                    <img src={logo} alt="Upload Study" onClick={this.openModal} />
                                    <h3>Upload Study</h3>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <StudyUploadTable id={this.props.id} ref={( dt ) => { this._grid = dt }} tabIndex="50" />
                            </div>
                            <div>
                                <br />
                            </div>
                            <div>
                            { uploadControl }
                            </div>
                        </div>

                        <br />
                        
                        <div className="btn-toolbar pull-right" data-toggle="buttons" >
                            { sendButton }
                            <button id="CloseButton" ref={(c) => {this.CloseButton = c;}} className="btn btn-secondry btn-sm ss-button" onClick={this.onCloseModal} tabIndex="54" >Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
});
