import Config from "../modules/config";

class WebService {
  static checkUrlFormat(url) {
    url = url.trim();

    if (url.length < 1) {
      return url;
    }

    if (url.endsWith("/") === false) {
      url += "/";
    }

    return url;
  }

  static getBaseUrl() {
    let url = window.location.origin;

    console.log("url-service::getBaseUrl: URL - " + url);

    if (url.startsWith("http://localhost:3000")) {
      console.log("url-service::getBaseUrl: LOCALHOST - " + Config.serverUrl);
      return Config.serverUrl;
    }

    return url;
  }
}

if (!String.prototype.startsWith) {
  // eslint-disable-next-line
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

export default WebService;
