import React from "react";
import CreateReactClass from "create-react-class";
import PropTypes from "prop-types";

import "react-dropdown/style.css";

//export default React.createClass({
const CheckboxFormatter = CreateReactClass({
  propTypes: {
    value: PropTypes.bool
  },

  getInitialState: function() {
    const v = this.props.value;
    return {
      value: v
    };
  },

  componentWillReceiveProps: function(prop) {
    this.setState({
      value: prop.value
    });
  },

  handleOnChange(evt) {},

  render: function() {
    return (
      <input
        type="checkbox"
        checked={this.state.value}
        onChange={this.handleOnChange}
        disabled="disabled"
      />
    );
  }
});

export default CheckboxFormatter;
