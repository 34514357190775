import React                            from 'react';
import Modal                            from 'react-modal';
import CreateReactClass                 from 'create-react-class';
import SA                               from 'sweetalert2';
import Moment                           from 'moment';

import Therapy                          from '../../services/therapy-service';
import Utility                          from '../../modules/utility';

import logo                             from './images/contact.png';

import '../../../node_modules/bootstrap/dist/css/bootstrap.css';

/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
  content : {
    top                 : '50%',
    left                : '50%',
    width               : '650px',
    height              : '337px',
    marginleft          : 'auto',
    marginRight         : 'auto',
    transform           : 'translate(-50%, -50%)',
    background          : 'white',
    borderColor         : 'black'
  }
};

export default CreateReactClass({

  getInitialState() {
    return {
        modalIsOpen: false,
        dob: ''
    };
  },

  openModal() {

    this.setState({modalIsOpen: true});
  },

  afterOpenModal: function() {
    // references are now sync'd and can be accessed.
  },

  onCloseModal: function(e) {
      e.preventDefault();
    this.setState({modalIsOpen: false});
  },

  onDateOfBirthChanged ( option ) {
    
        let dateFormat = 'YYYY-MM-DD';

        if (Utility.detectIE()) {
            dateFormat = 'DD/MM/YYYY';
        }
        
        //this.searchCriteria.endDateOfBirth = option;
        if (Moment(this.DateOfBirthControl.value, dateFormat).isValid()) {

            var currentDate = Moment(this.DateOfBirthControl.value, dateFormat)
            
            if ( currentDate.year() > Moment().year() ) {
                currentDate.year( Moment().year() );
            }

            this.DateOfBirthControl.value = currentDate.format( 'YYYY-MM-DD' );
        }
    },
      
    onSaveClick: function (e) {
      e.preventDefault();

      var firstname         = this.FirstNameControl.value;
      var surname           = this.SurnameControl.value;
      var dob               = Moment(this.DateOfBirthControl.value, "YYYY-MM-DD");
      var referringDoctor   = this.ReferringDoctorControl.value;
      var message           = "";
      var valid             = true;

      if ( firstname === "" || firstname === undefined ) {
          message   += "Invalid First Name entered.";
          valid     = false;
      }

      if ( surname === "" || surname === undefined ) {
          message   += "\nInvalid Surname entered.";
          valid     = false;
      }

      if ( dob === "" || !dob.isValid() || dob === undefined ) {
          message   += "\nInvalid Date Of Birth entered.";
          valid     = false;
      }

      if ( valid === false ) {

          SA("Validation Error", message, "error");
          return;
      }

      var refreshTable = this.props.refreshDataTable;

      Therapy.addTherapyPatient(firstname, surname, dob, referringDoctor).then(function(resp) {

          refreshTable();
      });

      this.setState({
          modalIsOpen: false
      });
    },

    onDobChanged: function (option) {

      this.dob = option;
    },

    render: function() {
        return (
            <div>
                <button className="btn btn-primary btn-sm" onClick={this.openModal} style={{width: '160px', margin: '3px'}}>Add Therapy Patient</button>
                <Modal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} style={customStyles} >

                    <div>

                        <div className="panel panel-default">
                            <div className="panel-body title-panel">
                                <div className="header">
                                    <img src={logo} title="Add Therapy Patient" alt="Add Therapy Patient" onClick={this.openModal} />
                                    <h3>Add Therapy Patient</h3>
                                </div>
                            </div>
                        </div>
                        <div >
                            <form className="form-horizontal">
                                <br />
                                <div className="form-group row">
                                    <label for="FirstNameControl" className="col-sm-4 control-label">* First Name :</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control form-control-sm" id="FirstNameControl" onChange={ this.onFirstNameChanged } ref={(c) => {this.FirstNameControl = c;}} placeholder="First Name" autoFocus tab="1" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="SurnameControl" className="col-sm-4 control-label">* Surname :</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control form-control-sm" id="SurnameControl" onChange={ this.onSurnameChanged } ref={(c) => {this.SurnameControl = c;}} placeholder="Surname" tab="2" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="DateOfBirthControl" className="col-sm-4 control-label">* Date Of Birth :</label>
                                    <div className="col-sm-8">
                                        <input type="date" className="form-control form-control-sm" id="DateOfBirthControl" onChange={this.onDateOfBirthChanged} ref={(c) => {this.DateOfBirthControl = c;}} placeholder="Date Of Birth" tab="3" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="ReferringDoctorControl" className="col-sm-4 control-label">Referring Doctor:</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control form-control-sm" id="ReferringDoctorControl" onChange={ this.onReferringDoctorChanged } ref={(c) => {this.ReferringDoctorControl = c;}} placeholder="Referring Doctor" tab="4" />
                                    </div>
                                </div>

                                <div className="btn-toolbar pull-centre" data-toggle="buttons">
                                    <button type="button" className="btn btn-primary btn-sm ss-button" onClick={this.onSaveClick} tab="5">OK</button>
                                    <button type="button" className="btn btn-primary btn-sm ss-button" onClick={this.onCloseModal} tab="6">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
});
