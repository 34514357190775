import axios from "axios";
import querystring from "querystring";
import moment from "moment";
import WebService from "./web-service";

class AuthService extends WebService {
  static login(username, password, cb) {
    var httpConfig = {
      baseURL: this.getBaseUrl(),
      headers: {
        post: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    };

    console.log(this.getBaseUrl() + "/api/login");

    var params = { username: username, password: password };

    console.log(this.getBaseUrl());

    axios
      .post("/api/login", querystring.stringify(params), httpConfig)
      .then(function(response) {
        if (response.data.authorized) {
          localStorage.authtoken = JSON.stringify(response.data.authToken);
          console.log(response.data.authToken);

          if (typeof cb === "function") cb(true, response.data.message);
        } else {
          if (typeof cb === "function") cb(false, response.data.message);
        }
      })
      .catch(function(error) {
        console.log(error);

        var message;

        if (error.response) {
          message = error.response.data.message;
        } else {
          message = error;
        }

        if (typeof cb === "function") cb(false, message);
      });
  }

  static logout() {
    delete localStorage.authtoken;
  }

  static loggedIn() {
    if (AuthService === null) return false;

    return AuthService.tokenExisted() && !AuthService.tokenExpired();
  }

  static getToken() {
    return localStorage.authtoken
      ? JSON.parse(localStorage.authtoken).token
      : null;
  }

  static getUser() {
    try {
      return localStorage.authtoken
        ? JSON.parse(localStorage.authtoken).data
        : null;
    } catch (ex) {
      delete localStorage.authtoken;
      return null;
    }
  }

  static tokenExisted() {
    return !!localStorage.authtoken;
  }

  static tokenExpired() {
    let user = AuthService.getUser();

    if (user == null) return true;

    let expiryTime = moment(user.expiryTime, moment.ISO_8601);

    return expiryTime <= moment();
  }

  static requireAuth(nextState, replace) {
    if (!AuthService.loggedIn()) {
      replace({
        pathname: "/login",
        state: {
          nextPathname: nextState.location.pathname
        }
      });
    }
  }

  static redirectFromLogin(nextState, replace) {
    if (AuthService.loggedIn()) {
      replace({
        pathname: "/"
      });
    }
  }
}

export default AuthService;
