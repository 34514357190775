import React from "react";
import { Redirect } from "react-router-dom";

import UserNameField from "../../controls/user-name-field";
import PasswordField from "../../controls/password-field";

import PageHeader from "../headers/page-header";

import authService from "../../services/auth-service";

import "./css/login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      redirect: false
    };

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    if (authService.tokenExisted() && authService.tokenExpired()) {
      this.setState({
        message: "Your session has expired"
      });
    }
  }

  submit(event) {
    var self = this;

    this._userNameField.validate();
    this._passwordField.validate();

    //stop the form to load action url after submit
    event.preventDefault();

    if (
      !this._userNameField.state.isValid ||
      !this._passwordField.state.isValid
    ) {
      return;
    }

    console.log("authService.login");

    authService.login(
      this._userNameField.state.value,
      this._passwordField.state.value,
      function(loggedIn, message) {
        if (loggedIn) {
          self.setState({
            redirect: true
          });
        } else {
          self.setState({
            redirect: false,
            message: message
          });
        }
      }
    );
  }

  render() {
    const { location } = this.props;

    if (this.state.redirect === true) {
      if (location.state && location.state.nextPathname) {
        return <Redirect to={location.state.nextPathname} />;
      } else {
        return <Redirect to="/" />;
      }
    }

    console.log("Render form");

    return (
      <form className="form-signin" onSubmit={this.submit}>
        <PageHeader />
        <br />
        <br />
        <br />
        <br />
        <UserNameField ref={c => (this._userNameField = c)} />
        <PasswordField ref={c => (this._passwordField = c)} />
        <p className="bg-danger">{this.state.message}</p>
        <button type="submit" className="btn btn-lg btn-primary btn-block">
          Sign in
        </button>
      </form>
    );
  }
}

export default Login;
