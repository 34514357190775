import React                            from 'react';
import CreateReactClass                 from 'create-react-class';
//import SAlert                           from 'sweetalert2';

//import Therapy                          from '../../modules/therapy';

import Logo                             from './images/delete.png';
import Studies                          from '../../services/study-service';

export default CreateReactClass({

    getInitialState: function() {

      return {
          id: this.props.value
      };
    },

    onDeleteClick: function (e) {
        e.preventDefault();

        var therapyStudyId = this.props.value;
        var onDeleteRow = this.props.onDeleteRow;

        // Therapy.deleteTherapyStudy(therapyStudyId).then((resp) => {

        //     if ( resp.data.success ) {

                onDeleteRow( therapyStudyId );
                Studies.removeId( therapyStudyId );
        //     } else {

        //         SAlert({
        //             title: "Study", 
        //             text: resp.data.message,
        //             type: "warning"
        //         });
        //     }
        // });
    },

    render: function () {

        return (
            <img src={Logo} title="Delete file" alt="delete" onClick={this.onDeleteClick} height="32" width="32" />
        )
    }
})
