import NumericUtility                   from '../modules/numericUtility';

var ScriptValidationService = {

    validateScript (script) {
        
        let response = this.validateEmptyValues( script );

        if (response.valid === false) {

            return response;
        }

        let message = 'Some parameters do not meet the physician requirements for therapy sign-off.';
        let isValid = true;
        //let physicianOverride   = this.PhysicianOverrideControl.checked;
        let result;

        if (script.isPrint) {

            message += ' Please contact the patient\'s sleep and respiratory physician for approval before continuing.<br/><br/>The following parameter(s) are outside acceptable ranges<br/>';
        } else {
            message += '<br/>';
        }
        
        if (script.physicianOverride) {

            return  { 
                valid: true, 
                message: 'Physician Override set' 
            };
        }

        // ---------------------------------------------------------------------------------------------------------------
        // Diagnostic AHI
        if (script.diagnosticAHI) {
            
            result = NumericUtility.checkDecimal('Diagnostic AHI', script.diagnosticAHI.toString(), 18, 1)

            if (result === true) {
                if (script.diagnosticAHI <= 0) {

                    message += '<br>Diagnostic AHI must be a positive number';
                    isValid = false;
                }
            } 
            else {

                message = message + '<br>' + result.message;
                isValid = false;
            }
        } else {

            message += '<br>Diagnostic AHI is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Post Therapy AHI
        if (script.postTherapyAHI) {

            result = NumericUtility.checkDecimal('Post Therapy AHI', script.postTherapyAHI.toString(), 18, 1)

            if (result === true) {

                if (script.postTherapyAHI <= 0) {

                    message += '<br>Post Therapy AHI must be a positive number';
                    isValid = false;
                }


                if (script.isPrint && script.postTherapyAHI >= 10) {

                    message += '<br>Post Therapy AHI must be less than 10';
                    isValid = false;
                }
            } 
            else {

                message = message + '<br>' + result.message;
                isValid = false;
            }
        } else {

            message += '<br>Post Therapy AHI is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Pre Therapy ESS
        if (script.preTherapyESS) {
            
            result = NumericUtility.checkDecimal('Pre Therapy ESS', script.preTherapyESS.toString(), 18, 0)

            if (result === true) {

                if (script.preTherapyESS <= 0) {

                    message += '<br>Pre Therapy ESS must be a positive number';
                    isValid = false;
                }

                if (script.isPrint && script.preTherapyESS >= 12) {

                    message += '<br>Pre Therapy ESS must be less than 12';
                    isValid = false;
                }
            } 
            else {

                message = message + '<br>' + result.message;
                isValid = false;
            }
        } else {

            message += '<br>Pre Therapy ESS is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Post Therapy ESS
        if (script.postTherapyESS) {
            
            result = NumericUtility.checkDecimal('Post Therapy ESS', script.postTherapyESS.toString(), 18, 0)

            if (result === true) {

                if (script.postTherapyESS <= 0) {

                    message += '<br>Post Therapy ESS must be a positive number';
                    isValid = false;
                }
            } 
            else {

                message = message + '<br>' + result.message;
                isValid = false;
            }
        } else {

            message += '<br>Post Therapy ESS is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Symptomatic Improvement Score
        if (script.symptomaticImprovementScore) {
            
            result = NumericUtility.checkDecimal('Symptomatic Improvement Score', script.symptomaticImprovementScore.toString(), 18, 1)

            if (result === true) {

                if (script.symptomaticImprovementScore <= 0) {

                    message += '<br>Symptomatic Improvement Score must be a positive number';
                    isValid = false;
                }
            } 
            else {

                message = message + '<br>' + result.message;
                isValid = false;
            }
        } else {

            message += '<br>Symptomatic Improvement Score is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Average Daily Usage
        if (script.averageDailyUsageHours) {
            
            result = NumericUtility.checkDecimal('Average Daily Usage', script.averageDailyUsageHours.toString(), 18, 2)

            if (result === true) {

                if (script.symptomaticImprovementScore <= 0) {

                    message += '<br>Average Daily Usage must be a positive number';
                    isValid = false;
                }

                if (script.isPrint && script.averageDailyUsageHours < 4) {

                    message += '<br>Average Daily Usage must be a four or greater';
                    isValid = false;
                }
            } 
            else {

                message = message + '<br>' + result.message;
                isValid = false;
            }
        } else {

            message += '<br>Average Daily Usage is a required field';
            isValid = false;
        }

        if (isValid !== true) {
            message += '<br><br>Please set Physician Override if you wish to continue.<br><br>Dr Jack Philpott<br>Mobile: 0404 069 474<br>Email: jack.philpott@sleepwa.com.au';
        }
            
        return { valid: isValid, message: message };
    },

    validateEmptyValues( script ) {

        let message = 'Some parameters do not meet the script basic requirements.<br>';
        let isValid = true;

        // ---------------------------------------------------------------------------------------------------------------
        // Diagnostic AHI
        if (script.diagnosticAHI || script.diagnosticAHI === 0) {
            
            if (script.diagnosticAHI <= 0) {

                message += '<br>Diagnostic AHI must be greater than zero';
                isValid = false;
            }
        } else {

            message += '<br>Diagnostic AHI is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Post Therapy AHI
        if (script.postTherapyAHI || script.postTherapyAHI === 0) {

            if (script.postTherapyAHI <= 0) {

                message += '<br>Post Therapy AHI must be greater than zero';
                isValid = false;
            }
        } else {

            message += '<br>Post Therapy AHI is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Pre Therapy ESS
        if (script.preTherapyESS || script.preTherapyESS === 0) {
            
            if (script.preTherapyESS <= 0) {

                message += '<br>Pre Therapy ESS must be greater than zero';
                isValid = false;
            }
        } else {

            message += '<br>Pre Therapy ESS is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Post Therapy ESS
        if (script.postTherapyESS || script.postTherapyESS === 0) {

            if (script.postTherapyESS <= 0) {

                message += '<br>Post Therapy ESS must be greater than zero';
                isValid = false;
            }
        } else {
            
            message += '<br>Post Therapy ESS is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Symptomatic Improvement Score
        if (script.symptomaticImprovementScore || script.symptomaticImprovementScore === 0) {

            if (script.symptomaticImprovementScore <= 0) {

                message += '<br>Symptomatic Improvement Score must be greater than zero';
                isValid = false;
            }
        } else {
            
            message += '<br>Symptomatic Improvement Score is a required field';
            isValid = false;
        }
            
        // ---------------------------------------------------------------------------------------------------------------
        // Average Daily Usage
        if (script.averageDailyUsageHours || script.averageDailyUsageHours === 0) {

            if (script.averageDailyUsageHours <= 0) {

                message += '<br>Average Daily Usage must be greater than zero';
                isValid = false;
            }
        } else {
            
            message += '<br>Average Daily Usage is a required field';
            isValid = false;
        }

        // ---------------------------------------------------------------------------------------------------------------
        // Current Mask
        if (!script.currentMask || 0 === script.currentMask.trim().length) {

            message += '<br>Current Mask is a required field';
            isValid = false;
        }

        // ---------------------------------------------------------------------------------------------------------------
        // Current Machine Preasure Settings
        if (!script.currentMachinePressureSettings || 0 === script.currentMachinePressureSettings.trim().length) {

            message += '<br>Current Machine Pressure Settings is a required field';
            isValid = false;
        }
        
        if (isValid !== true) {
            message += '<br><br>You can not print or save a script with one or more validation errors.';
        }
            
        return { valid: isValid, message: message };
    }
};

export default ScriptValidationService;

