import React                            from 'react';
import ClassNames                       from 'classnames';

class PasswordField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            errorMessage: '',
            isValid: null
        }

        this.setPassword = this.setPassword.bind(this);
        this.validate = this.validate.bind(this);
    }

    setPassword(event) {
        var curVal = event.target.value;
        this.setState({ value: curVal }, function() {
            this.validate();
        });
    }

    validate() {
        if (this.state.value && this.state.value.length > 0) {
            this.setState({
                errorMessage: '',
                isValid: true
            });
        } else {
            this.setState({
                errorMessage: 'Password is required',
                isValid: false
            });
        }
    }

    render() {
        var groupCssClass = ClassNames({
            'form-group': true,
            'has-error': this.state.isValid === false,
            'has-success': this.state.isValid === true
        });

        return (
            <div className={groupCssClass}>
                <label className="control-label" htmlFor="inputPassword">Password</label>
                <input
                    type="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    value={this.state.value}
                    onChange={this.setPassword} />
                <span className="help-block">{this.state.errorMessage}</span>
            </div>
        );
    }
}

export default PasswordField;
