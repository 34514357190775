import React                                    from 'react';

import TherapyTable                             from '../therapy/therapy-table';
import ExternalSearchToolbar                    from '../therapy/toolbars/external-search-toolbar';
import InternalSearchToolbar                    from '../therapy/toolbars/internal-search-toolbar';

import AuthService                              from '../../services/auth-service';
import TherapyService                           from '../../services/therapy-service';


class Home extends React.Component {

    constructor(props) {
        super(props);

        let user = AuthService.getUser();
        var locationId = '0';
        var statusId = '1';

        if (user && user.internal === false) {

            locationId = user.locationId;
            statusId = '7';
        }

        let filter = {therapyStatusId: statusId, verified: false, startReportDate: null, endReportDate: null, firstName: '', surname: '', dateOfBirth: null, locationId: locationId, sourceId: '0', showDeleted: '0'};

        this.state = {
            locations: [],
            sources: [],
            filter: filter
        }

        this.clearFilter = this.clearFilter.bind(this);
        this.containDefaultLocation = this.containDefaultLocation.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.refreshDataTable = this.refreshDataTable.bind(this);
    }

    componentDidMount () {

        TherapyService.init((locations, sources, therapyTypes) => {

            let user = AuthService.getUser();
            var locationId = '0';
            var sourceId = '0'
            var statusId = '1';

            if (user.internal === false) {

                sourceId = user.locationId;
                statusId = '7';
            }

            let filter = {therapyStatusId: statusId, verified: false, startReportDate: null, endReportDate: null, firstName: '', surname: '', dateOfBirth: null, locationId: locationId, sourceId: sourceId, showDeleted: '0'};

            if (this.state.sources.length > 0) {
                filter.sourceId = this.state.sources[0].value;
            }

            this.setState({
                locations: locations,
                sources: sources,
                therapyTypes: therapyTypes,
                filter: filter
            }, () => {

                this.onFilterUpdate(this.state.filter)
            })
        });
    }
    // getInitialState () {

    //     let user = authService.getUser();
    //     var locationId = '0';
    //     var statusId = '1';

    //     if (user.internal === false) {

    //         locationId = user.locationId;
    //         statusId = '7'
    //     }

    //     let filter = {therapyStatusId: statusId, verified: false, startReportDate: null, endReportDate: null, firstName: '', surname: '', dateOfBirth: null, locationId: locationId, sourceId: '0', showDeleted: '0'};

    //     return {
    //         locations: [],
    //         sources: [],
    //         filter: filter
    //     };
    // }

    onFilterUpdate ( filter ) {

        console.log('onFilterUpdate: ' + filter.therapyStatusId)

        this.setState({
            filter: filter,
        });

        this._dataTable.search(filter)

        console.dir(this._dataTable);
    }

    refreshDataTable ( e ) {

        this.onFilterUpdate(this.state.filter);
    }

    clearFilter () {

        this.setState({
            key: Math.random()
        });
    }

    containDefaultLocation( list ) {

        var i = list.length;

        while ( i-- ) {
            if ( list[i].value === "0" ) {
                return true;
            }
        }
        return false;
    }

    render() {

        let user = AuthService.getUser();
        var filter = this.state.filter;

        console.log( 'render: ' + filter.therapyStatusId )
        
        console.log( 'Load locationDropDownData' );
        let locationDropDownData = this.state.locations.map((loc) => {
            return {
                value: loc.id,
                label: loc.title
            }
        });

        // Need to add this because the get all location is not a Therapy record
        if ( !this.containDefaultLocation( locationDropDownData ) ) {
            console.log( 'Load default locationDropDownData' )
            locationDropDownData.unshift({
                value: '0',
                label: "*"
            });
        }

        console.log( 'Load sourceDropDownData' );
        let sourceDropDownData = this.state.sources.map((s) => {
            return {
                value: s.id,
                label: s.title
            }
        });

        console.log( 'Load therapyTableControl' );
        var therapyTableControl =  <div>Loading ...</div>;

        if (this.state.locations.length > 0 && this.state.sources.length > 0) {

            therapyTableControl = <TherapyTable ref={(dataTable) => {this._dataTable = dataTable}} 
                                                locations={this.state.locations} 
                                                sources={this.state.sources} 
                                                therapyTypes={ this.state.therapyTypes }
                                                therapyStatusId={this.state.filter.therapyStatusId} 
                                                showDeleted={this.state.filter.showDeleted} 
                                                internal={user.internal}
                                                refreshDataTable={this.refreshDataTable} />
        }

        var searchToolbar;

        if (user && user.internal) {

            console.log( 'Load Internal Search Toolbar' );
            //searchToolbar = <div>Toolbar</div>
            searchToolbar = <InternalSearchToolbar ref="searchControl" updateFilter={this.onFilterUpdate} clearFilter={this.clearFilter} locations={locationDropDownData} sources={sourceDropDownData} />
        }
        else {
            console.log( 'Load External Search Toolbar' );
            //searchToolbar = <div>Toolbar</div>
            searchToolbar = <ExternalSearchToolbar ref="searchControl" updateFilter={this.onFilterUpdate} clearFilter={this.clearFilter} locations={locationDropDownData} sources={sourceDropDownData} />
        }

        return (
            <div key={this.state.key}>
                <br />
                <br />
                {searchToolbar}

                {therapyTableControl}
            </div>
        )
    }   
}

export default Home;