import React                            from 'react';
import Modal                            from 'react-modal';
import CreateReactClass                 from 'create-react-class';
import Moment                           from 'moment';
import SAlert                           from 'sweetalert2';

import Therapy                          from '../../services/therapy-service';
import Referrals                        from '../../services/referral-service';
import Helpers                          from '../../modules/stringHelpers'

import ReferralUploadTable              from './referral-upload-table';
import FileUpload                       from '../../controls/file-upload';

import logo                             from './images/attachments.png';

/*
The app element allows you to specify the portion of your app that should be hidden (via aria-hidden)
to prevent assistive technologies such as screenreaders from reading content outside of the content of
your modal.  It can be specified in the following ways:

* element
Modal.setAppElement(appElement);

* query selector - uses the first element found if you pass in a class.
Modal.setAppElement('#your-app-element');

*/
const customStyles = {
  content : {
    top                     : '50%',
    left                    : '50%',
    width                   : '1100px',
    height                  : '585px',
    marginleft              : 'auto',
    marginRight             : 'auto',
    transform               : 'translate(-50%, -50%)',
    background              : 'white',
    borderColor             : 'black'
  }
};

export default CreateReactClass({

    getInitialState () {

        // this.afterOpenModal = this.afterOpenModal.bind(this);
        // this.fileIsValid = this.fileIsValid.bind(this);
        // this.onCloseModal = this.onCloseModal.bind(this);
        // this.onDrop = this.onDrop.bind(this);
        // this.onSendClick = this.onSendClick.bind(this);
        // this.openModal = this.openModal.bind(this);
        // this.postUpload = this.postUpload.bind(this);
        // this.render = this.render.bind(this);
        
        return {
            referrals: Referrals.list(),
            modalIsOpen: false
        };
    },

    openModal () {

        Referrals.reset();

        this.setState({
            modalIsOpen: true
        });

        // therapy.getTherapyAttachments(this.props.id).then(function(response) {
        //     this._dataTable.refreshTable(response.data.value)
        // }.bind(this));
    },

    afterOpenModal () {
        // references are now sync'd and can be accessed.
    },

    onCloseModal () {
        this.setState({
            modalIsOpen: false
        });
    },

    onDrop( files ) {

        var self = this;
        
        for (var i = 0; i < files.length; i++) {

            if (files[i].size === 0) {
                continue;
            }

            Referrals.addFile( files[i] )
                .then(( result ) => {

                    if ( result ) {

                        self._grid.refreshTable( Referrals.list() );
                    }
                })
                .catch(err => {

                    SAlert({
                        title: "Referral Upload",
                        html: "Failed to load referral",
                        type: "warning"
                    })
                });
        };
    },

    onSendClick () {

        if (!Referrals.hasValid()) {

            SAlert({ 
                title: 'Referral Upload', 
                html: 'There are <b>NO valid files</b> to send.',
                type: 'error'
            })

            //this.onCloseModal();
            return;
        }
        else {

            let files = Referrals.list();
            var isValid = true;
            var message = 'The following files were not sent.<br />';
    
            for (var i = 0; i < files.length; i++) {
    
                if (files[i].isValid) {
    
                    if (!files[i].firstName || files[i].fileName === 0 ) {
    
                        message += '<br />' + files[i].name + ' First Name, Surname and Date of Birth are required';
                        isValid = false;
                        continue;
                    };

                    if (!files[i].surname || files[i].surname === 0 ) {
                        
                        message += '<br />' + files[i].name + ' First Name, Surname and Date of Birth are required';
                        isValid = false;
                        continue;
                    };

                    if (!files[i].dateOfBirth ) {
                    
                        files[i].message = 'First Name, Surname and Date of Birth are required';
                        message += '<br />' + files[i].name + ' First Name, Surname and Date of Birth are required';
                        isValid = false;
                        continue;
                    };

                    this.postUpload(files[i]);

                    files[i].sent = true;
                }
            }
            
            this.setState({
                referrals: []
            })

            Referrals.remove();
        
            this.setState({
                referrals: Referrals.list()
            });

            if (isValid) {

                SAlert({
                    title: 'Referral Uploads',
                    html: 'Referral upload complete',
                    type: 'info',
                    width: '1000px'
                })

                this.onCloseModal()
            }
            else {
                message += '<br /><br />Please resend these files.'

                SAlert({
                    title: 'Referral Uploads',
                    html: message,
                    type: 'error',
                    width: '1000px'
                });
            }
        }
    },
        
    refreahTable( newRows ) {

        this.setState({
            rows: newRows
        })
    },

    fileIsValid (file) {

        if (Helpers.isEmpty(file.surname)) {
            return false;
        }

        return true;
    },

    postUpload (file) {

        var dob = Moment(file.dateOfBirth, "DD/MM/YYYY");

        Therapy.uploadReferral(file.firstName, file.surname, dob, file.name, file.image)
        .then(function (resp) {

            if (resp.status === 200) {
                file.sent = true;
            } else {
                file.desc = resp.data;
            }
        }).catch(err => {

            SAlert({
                title: "Referral Upload",
                html: err,
                type: "warning"
            })
        });
    },

    render () {

        let text = <p>Drag and drop some files here, or click to select files to upload.<br /><br />Must be a PDF Document.</p>
        
        return (
            <div>
                <button className="btn btn-primary btn-sm" onClick={this.openModal} style={{width: '160px', margin: '3px'}} tabIndex="21" >Upload Referral</button>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    style={customStyles} >
                    <div >

                        <div className="panel panel-default">
                            <div className="panel-body title-panel">
                                <div className="header">
                                    <img src={logo} alt="Add Therapy Patient" onClick={this.openModal} />
                                    <h3>Upload Referral</h3>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <ReferralUploadTable id={this.props.id} ref={( dt ) => { this._grid = dt }} tabIndex="50" />
                            </div>
                            
                            <br />
                            
                            <div>
                                <FileUpload Id={this.props.id} ref={(c) => {this.fileUpload = c;}} onDrop={ this.onDrop } fullText={text} tabIndex="60" />
                            </div>
                        </div>

                        <br />

                        <div className="btn-toolbar pull-right" data-toggle="buttons" >
                            <button id="SendButton" ref={(c) => {this.SendButton = c;}} className="btn btn-primary btn-sm ss-button" onClick={this.onSendClick} tabIndex="61" >Send</button>
                            <button id="CloseButton" ref={(c) => {this.CloseButton = c;}} className="btn btn-secondary btn-sm ss-button" onClick={this.onCloseModal} tabIndex="62" >Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
});
