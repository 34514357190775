import React                                    from 'react';
import { Route, Redirect, Switch }              from 'react-router-dom';

import PageHeader                               from './components/headers/page-header';
import Home                                     from './components/home/home';
import Login                                    from './components/login/login';
import Logout                                   from './components/logout/logout';

import PrivateRoute                             from './controls/private-route';

import AuthService                              from './services/auth-service';

import './css/login.css';
import './css/header.css';

const NoMatch = ({ location }) => (
  <div className='ui inverted red raised very padded text container segment'>
      <strong>Error!</strong> No route found matching:
      <div className='ui inverted black segment'>
          <code>{location.pathname}</code>
      </div>
  </div>
);

class App extends React.Component {

    render() {

        if (!AuthService.loggedIn() ) {
            return (
                <div>
                    <Switch>
                        <PrivateRoute path='/home' component={Home} />
                        <Route path='/login' component={Login} />
                        <Route path='/logout' component={Logout} />
                        <Route path='/' render={() => (
                            <Redirect to='/home' />
                        )} />
                        
                        <Route component={NoMatch} />
                    </Switch>

                    <Redirect to="/login" />
                </div>
            );
        }

        return (
            <div>

                <PageHeader />

                <div className="container-fluid">
                    {this.props.children}
                </div>

                <div className="container-fluid">
                    {this.props.children}
                    <Switch>
                        <Route path='/home' component={Home} />
                        <Route path='/login' component={Login} />
                        <Route path='/logout' component={Logout} />
                        <Route path='/' render={() => (
                            <Redirect to='/home' />
                        )} />

                        <Route component={NoMatch} />
                    </Switch>
                </div>
            </div>
        )
    }
};

export default App;
