import React from "react";
import CreateReactClass from "create-react-class";
import ReactDataGrid from "react-data-grid";

import Therapy from "../../services/therapy-service";

// -------------------------------------------------------------------------------------------------
//  CellRenderer
// -------------------------------------------------------------------------------------------------
var CellRenderer = CreateReactClass({
  //class CellRenderer extends React.Component {
  getInitialState() {
    return { key: Math.random() };
  },

  onKeyDown(e) {
    if (this.props.column === null || this.props.column === undefined) {
      return;
    }

    if (this.props.column.key === "verified") {
      if (e.keyCode !== 32) {
        return;
      }

      if (this.props.rowData.therapyStatusId !== 1) {
        return;
      }

      Therapy.setTherapyVerified(this.props.rowData.id);

      if (this.props.rowData.verified === true) {
        this.props.rowData.verified = false;
      } else {
        this.props.rowData.verified = true;
      }

      return;
    }

    if (this.props.column.key === "therapyDetails") {
      if (e.keyCode === 13) {
        this.props.column.openModal(
          this.props.rowData.id,
          this.props.rowData.therapyDetails
        );
      }
    }

    if (this.props.column.key === "comment") {
      if (e.keyCode === 13) {
        this.props.column.openModal(
          this.props.rowData.id,
          this.props.rowData.comment
        );
      }
    }
  },

  onClick(cell) {
    if (this.props.column === null || this.props.column === undefined) {
      return;
    }

    if (this.props.column.key === "verified") {
      Therapy.setTherapyVerified(this.props.rowData.id);

      this.props.rowData.verified = true;

      return;
    }

    if (this.props.column.key === "therapyDetails") {
      this.props.column.openModal(
        this.props.rowData.id,
        this.props.rowData.therapyDetails
      );

      return;
    }

    if (this.props.column.key === "comment") {
      this.props.column.openModal(
        this.props.rowData.id,
        this.props.rowData.comment
      );

      return;
    }

    if (this.props.column.key === "NextReviewDateId") {
      this.props.column.increaseReviewDate(this.props.rowData.id);
    }
  },

  render() {
    let cellControl = (
      <ReactDataGrid.Cell
        id="theCell"
        ref={c => {
          this.theCell = c;
        }}
        {...this.props}
        openModal={this.props.openModal}
      />
    );

    if (
      this.props.column.key === "comment" ||
      this.props.column.key === "therapyDetails"
    ) {
      cellControl = (
        <ReactDataGrid.Cell
          id="theCell"
          ref={c => {
            this.theCell = c;
          }}
          {...this.props}
          openModal={this.props.openModal}
          style={{ textAlign: "middle" }}
        />
      );
    }

    return (
      <div
        key={this.state.key}
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
      >
        {cellControl}
      </div>
    );
  }
});

// -------------------------------------------------------------------------------------------------
//  RowRenderer
// -------------------------------------------------------------------------------------------------
//export default CreateReactClass({
class RowRenderer extends React.Component {
  setScrollLeft(scrollBy) {
    //if you want freeze columns to work, you need to make sure you implement this as a pass through
    this.theRow.setScrollLeft(scrollBy);
  }

  getRowStyle() {
    return {
      color: this.getRowBackground()
    };
  }

  getRowBackground() {
    return this.props.row.partnerAdded ? "blue" : "black";
  }

  render() {
    //here we are just changing the style
    //but we could replace this with anything we liked, cards, images, etc
    //usually though it will just be a matter of wrapping a div, and then calling back through to the grid
    //return (<div style={this.getRowStyle()}><ReactDataGrid.Row ref="row" {...this.props} /></div>)

    //var cellRenderer = <CellRenderer openModal={this.props.openModal} {...this.props} />;

    return (
      <div style={this.getRowStyle()}>
        <ReactDataGrid.Row
          id="theRow"
          ref={c => {
            this.theRow = c;
          }}
          {...this.props}
          cellRenderer={CellRenderer}
        />
      </div>
    );
  }
}

export default RowRenderer;
